import Splide from "@splidejs/splide";
import {Grid} from '@splidejs/splide-extension-grid';

export const heroes = () => {
    if (document.querySelectorAll('.heroes__list').length) {
        new Splide('.heroes__list', {
            arrows: false,
            pagination: true,
            type: 'loop',
        }).mount();
    }

    // const news_items = document.querySelectorAll('.news-list__container .glide__slide');
    //
    // if (news_items.length) {
    //     new Splide('.news-list__container .splide', {
    //         arrows: false,
    //         pagination: true,
    //         perPage : 2,
    //         breakpoints: {
    //             640: {
    //                 destroy: true,
    //             },
    //         }
    //     }).mount();
    // }
}
