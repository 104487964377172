export const nav = () => {
    const menu = document.querySelector('#menu-primary');

    if (menu) {
      // Add menu behavior
    }
}

export const navHeight = () => {
    const nav_height = document.querySelector('#menu-primary');
    if (nav_height) {
        document.body.style.setProperty('--h-nav', `${Math.round(nav_height.getBoundingClientRect().height)}px`);
    }
    document.body.style.setProperty('--h-body', `${document.body.getBoundingClientRect().height}px`)
    document.body.style.setProperty('--h-window', `${window.innerHeight}px`)
}

export const navSticky = (selector) => {

    const nav = document.querySelectorAll(selector);
    let scrollDiff = 0;

    return (scrollY) => {
        const goingDown = scrollDiff < scrollY;
        const header = document.querySelectorAll('.header--main > div')[0];

        if (scrollY > (header.getBoundingClientRect().height * 0.8)) {
            nav.forEach(el => el.classList.add('menu--bg'));
        } else {
            nav.forEach(el => el.classList.remove('menu--bg'));
        }

        if (goingDown && scrollY > 200) {
            nav.forEach(el => el.classList.add('menu--fix'));
        } else if (!goingDown && scrollY > 200) {
            nav.forEach(el => el.classList.remove('menu--fix'));
        }

        if (scrollY > 100) {
            nav.forEach(el => el.classList.add('menu--small'));
        } else {
            nav.forEach(el => el.classList.remove('menu--small'));
        }


        scrollDiff = scrollY;
    };
}
