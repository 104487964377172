export const scroll = (...callbacks) => {
    let yScroll = 0;

    setTimeout(() => scrollInit(callbacks), 1000);
}

const scrollInit = (callbacks) => {

    window.addEventListener("scroll", () => {
        let scrollY = (window.pageYOffset > 0) ? window.pageYOffset : 0;
        document.documentElement.style.setProperty('--scroll-y', scrollY + 'px');
        if (callbacks.length > 0) {
            callbacks.forEach((callback) => callback(scrollY));
        }
    })
}
