export const modal = () => {
  // Burger menu
    const btns = document.querySelectorAll('button[data-target]');

    if (btns) {
        btns.forEach(btn => {
            const targetSelector = btn.getAttribute('data-target');
            const targetButtons = document.querySelectorAll(`[data-target="${targetSelector}"]`);
            const targets = document.querySelectorAll(targetSelector);
            btn.addEventListener('click', (event) => {
                event.preventDefault();
                if (btn.classList.contains('active')) {
                    document.body.classList.remove('show--modal');
                  //btn.classList.remove('active');
                    targets.forEach(m => {
                        m.scrollTo(0, 0);
                        m.dispatchEvent(new CustomEvent('modal-close'));
                        m.classList.remove('active');
                    });
                  // Activate all button for the same target
                    targetButtons.forEach(b => b.classList.remove('active'))
                } else {
                    document.body.classList.add('show--modal');
                  //btn.classList.add('active');

                    targets.forEach(m => {
                        m.scrollTo(0, 0);
                        m.dispatchEvent(new CustomEvent('modal-open', {
                            detail: {
                                'trigger' : btn,
                            }
                        }));
                        m.classList.add('active');

                        m.querySelectorAll('canvas').forEach((canvas) => {
                            canvas.dispatchEvent(new CustomEvent('animate'));
                        });
                    });

                  // Deactivate all button for the same target
                    targetButtons.forEach(b => b.classList.add('active'))
                }
            });
        })
    }
}
