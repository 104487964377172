// Convert from degrees to radians.
Math.radians = function (degrees) {
    return degrees * Math.PI / 180;
}

export default class Hexa {
    constructor(canvas, _x, _y, _size, _row, _column, _alpha, _color)
    {
      // Fix float javascript

        this.context = canvas.getContext('2d');
        this.props = {
            x: _x,
            y: _y,
            size: _size,
            alpha: _alpha,
            finalAlpha: _alpha,
            speed: (_alpha > 0 ) ? _alpha / 10 : 10,
        }

        this.row = _row;
        this.stroke = false;
        this.column = _column;
        this.color = this.rgb(_color);
        this.originalColor = this.rgb(_color);
        this.fixNumber();
        this.originalProps = {...this.props};

        this.slide = (_alpha < 100) ? 'down' : 'up';
      //this.slide = true;
    }

    update(_props)
    {
        this.props = Object.assign(this.props, _props);

        this.fixNumber();
    }

    fixNumber()
    {
        Object.keys(this.props).forEach(
            function (key) {
                this.props[key] = this.number(this.props[key]);
            }.bind(this)
        );
    }

    number(number)
    {
        return Math.round(number * 1e2) / 1e2;
    }

    draw()
    {
        this.context.beginPath();

      //this.context.translate(0.5,0.5);
        const startPoint = this.hexCorner(0);
        this.context.moveTo(startPoint.x, startPoint.y);
        for (let side = 0; side < 7; side++) {
            const line = this.hexCorner(side);
          //   console.log(line);
            this.context.lineTo(line.x, line.y);
        }
        const color = this.rgba(this.props.alpha / 100);
        this.context.fillStyle = color;

        if (parseInt(this.props.finalAlpha) === 100 || this.stroke) {
            this.context.strokeStyle = color;
        } else {
            this.context.strokeStyle = 'transparent';
        }

        this.context.fill();
        this.context.stroke();
    }

    rgb(_hex)
    {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(_hex)) {
            c = _hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
        }

        throw new Error('Bad Hex');
    }

    rgba(_alpha)
    {
        return 'rgba(' + this.color.join(',') + ',' + _alpha + ')';
    }

    colorFromTo(from, to, step = 1)
    {
        if (from > to) {
            return from - step;
        } else if (from < to) {
            return from + step;
        } else {
            return to;
        }
    }

    hexCorner(i)
    {

        const angle = Math.radians(60 * i - 30)

        return {
            x: this.props.x + this.props.size * Math.cos(angle),
            y: this.props.y + this.props.size * Math.sin(angle),
        };
    }
}
