import {debounce} from "@scripts/tools/debounce.js";


export const resize = (...callbacks) => {
    const debounceResize = debounce(function () {
        if (callbacks.length > 0) {
            callbacks.forEach((callback) => callback());
        }
    }, 250);
    window.addEventListener("resize", debounceResize);
    window.dispatchEvent(new Event('resize'));
}
