import Splide from "@splidejs/splide";
import {Grid} from "@splidejs/splide-extension-grid";

export const gallery = () => {

    let dimensions = [
    [1, 1],
    [2, 1],
    ];

    const gallery_items = document.querySelectorAll('.gallery__slide .splide__slide');
    if (gallery_items.length) {
        const loop = Math.round(gallery_items.length / dimensions.length);

        for (let i = 1; i < loop; i++) {
            dimensions = dimensions.concat(dimensions);
        }

        const slider = new Splide('.gallery__slide', {
            // type: 'loop',
            perPage: 2,
            perMove: 1,
            pagination: false,
            breakpoints: {
                1024: {
                    perPage: 1,
                    grid: false,
                },
            },
            grid: {

                dimensions: dimensions,
                gap: {
                  //row: '1rem',
                },
            },
        })

        setTimeout(() => {
            slider.mount({Grid});
        }, 1000);
    }

    const gallery_modal = document.querySelector('#modal-gallery-products');
    if (gallery_modal) {
        let gallery_modal_slide = new Splide('.gallery__slide--modal', {
            // type: 'loop',
            perPage: 1,
            perMove: 1,
            pagination: false,

        });

        gallery_modal.addEventListener('modal-open', (event) => {
          // get Clicked image index

             const trigger = event.detail.trigger;
            const index = trigger.getAttribute('data-index');

            gallery_modal_slide.mount();

            setTimeout(() => {
                gallery_modal_slide.go(parseInt(index));
            }, 200);
        });

        gallery_modal.addEventListener('modal-close', () => {
            console.log(gallery_modal_slide)
            gallery_modal_slide.destroy();
        });
    }
}
