const grids = {
  // Products header grid
    'default': {
        fillGrid: 'left',
        fillColor: '0',
        animate:'parallax',
        grid: [
        [20, 0, 30, 50, 20, 0],
        [0, 0, 130, 0, 0],
        [20, 0, 30, 15, 20],
        [0, 20, 0, 130, 10, 20],
        [30, 0, 20, 0, 20, 50],
        [0, 0, 5, 0, 0],
        ],
    },
  // Products header grid
    'product': {
        scroll: true,
        fade: true,
        play: true,
        fillGrid: 'right',
        fillColor: '0',
        animate: 'fadeFrom',
        grid: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 90],
          [0, 0, 0, 80, 0, 0, 40, 0, 0],
          [0, 0, 0, 0, 0, 15, 0, 5, 60],
          [0, 0, 0, 60, 0, 0, 40, 0, 90],
          [0, 0, 0, 0, 40, 0, 20, 95, 70],
          [0, 0, 0, 0, 10, 60, 20, 99],
        ],
    },
    'product-bg': {
        fade: true,
        play: true,
        fillGrid: 'right',
        fillAlpha: '00',
        animate: 'fadeFrom',
        grid: [
          [100,100, 100, 100, 100, 100, 100, 100, 100,100],
          [100,100, 100, 100, 100, 100, 100, 100, 0],
          [100,100, 100, 0, 100, 100, 40, 100, 100],
          [100,100, 100, 100, 100, 0, 100, 0, 0],
          [100,100, 100, 0, 100, 100, 0, 0, 0],
          [100,100, 100, 100, 0, 0, 0, 0, 0],
          [100,100, 100, 100, 0, 0, 0, 0, 0],
          [100,100, 100, 100, 0, 0, 0, 0, 0],
          [0,0, 0, 0, 0, 0, 0, 0],
        ],
    },
    'category': {
        scroll: true,
        fade:true,
        play: true,
        animate:'fadeTo',
        grid: [
        [120, 130, 20, 0, 0, 0, 0, 220, 0],
        [0, 0, 130, 0, 0, 0, 120, 240, 240],
        [0, 15, 0, 20, 0, 0, 110, 20, 10],
        [130, 0, 0, 0, 0, 0, 130, 0, 110],
        [170, 0, 0, 0, 0, 0, 120, 110, 170],
        [155, 0, 0, 120, 0, 140, 160, 5, 0],
        ],
    },
    'single': {
        scroll: true,
        fade:true,
        play: true,
        animate:'fadeTo',
        fillGrid: 'right',
        grid: [
        [0, 0, 0, 130, 10, 110, 0, 120, 10, 30, 20],
        [0, 0, 10, 10, 0, 120, 120, 105, 10, 30, 20],
        [0, 0, 20, 0, 120, 10, 110, 10, 15, 20],
        [0, 0, 10, 0, 0, 0, 0, 5, 0, 5],
        [0, 0, 110, 5, 0, 0, 0, 130, 0, 10],
        [0, 0, 0, 106, 0, 110, 10, 5, 0, 0],
        ],
    },
    'sub': {
        animate:'parallax',
        fillAlpha: '00',
        fillColor: '0',

        grid: [
          [0, 0, 0, 0, 0, 0, 0, 0],
          [0, 3, 0, 0, 0, 0, 0, 110, 20],
          [0,0, 205, 15, 0, 0, 15, 115, 10],
          [0,0, 102, 105, 0, 0, 10, 125, 120],
        ],
    },
    'sep': {
        animate: 'parallax',
      fade:true,
      play: true,
        grid: [
          [0, 0, 0, 125, 5, 135, 0, 120, 10, 30, 20],
          [0, 0, 10, 0, 0, 0, 140, 10, 0, 10],
          [0, 0, 0, 10, 0, 115, 125, 105, 10, 30, 20],
          [0, 0, 0, 0, 110, 10, 115, 20, 40, 20],
          [0, 0, 0, 5, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 110, 0, 110, 10, 5, 0, 0],
        ],
    },
    'product-list': {
        fillGrid:'bottom',
        // fillAlpha: '00',
        // fillColor: '0',
        animate: 'parallax',
        grid: [
          [0, 0, 0, 125, 5, 135, 0, 120, 205, 0, 20],
          [0, 0, 0, 0, 0, 0, 140, 10, 0, 12],
          [0, 0, 115, 5, 0, 0, 0, 130, 0, 3],
          [0, 0, 0, 110, 0, 105, 15, 5, 0, 0],
        ],
    },
    'front': {
        scroll: true,
        fade: true,
        play: true,
        animate:'fadeTo',
        grid: [
          [200, 200, 200, 0, 0, 0, 30, 120, 240],
          [200, 190, 200, 0, 0, 230, 0, 0, 0],
          [200, 200, 200, 0, 0, 0, 260, 230, 0],
          [200, 160, 0, 0, 0, 240, 240, 0, 0],
          [200, 190, 200, 199, 10, 20, 220, 240, 0],
          [20, 0, 150, 5, 0, 120, 20, 0, 140, 20],
        ],
    },
    'front-reverse': {
        scroll: true,
        fade: true,
        play: true,
        animate:'fadeTo',
        grid: [
        [240, 120, 30, 0, 0, 0, 200, 200, 200],
        [0, 0, 0, 230, 0, 0, 200, 190, 200],
        [0, 230, 260, 0, 0, 0, 200, 200, 200],
        [0, 0, 240, 240, 0, 0, 0, 160, 200],
        [0, 240, 220, 10, 20, 199, 200, 190, 200],
        [20, 140, 0, 20, 120, 0, 5, 150, 0, 20],
        ],
    },
    'menu': {
        scroll: false,
        animate: 'toleft',
        fillGrid: 'left',
        grid: [
        //[100, 100],

        [0,0, 0, 0, 0, 40, 100, 100, 100, 100,100, 100],
        [0,0, 0, 0, 0, 0, 100, 100, 100, 100,100, 100],
        [0,0, 0, 0, 5, 0, 100, 100, 100, 100,100, 100],
        [0,0, 0, 0, 0, 40, 100, 100, 100, 100,100, 100],
        [0,0, 0, 0, 0, 0, 0, 40, 100, 100,100, 100],
        [0,0, 0, 0, 0, 0, 40, 60, 100, 100,100, 100],
        [0,0,0, 0, 0, 0, 0, 40, 60, 100,100, 100],
        ],
    },
    'full': {
        animate: 'toleft',
        grid: [
        //[100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        ],
    },
}

export default grids;
