export const videoplayer = () => {
    document.querySelectorAll('.playback_button').forEach(b => {
        const container = b.closest('.video-responsive');

        if (container) {
            let isPlaying = false;
            const video = container.querySelector('video');

            b.addEventListener('click', e => {
                e.preventDefault();
                if (!isPlaying) {
                    video.play();
                    video.setAttribute('controls', '');

                    video.setAttribute('controlsList', 'nodownload noplaybackrate');
                    container.classList.add('is-playing');

                    b.querySelector('.playback_button-svg').classList.add('hide');
                    b.querySelector('.pause_button-svg').classList.remove('hide');
                    isPlaying = true;
                } else {
                    video.pause();
                    b.querySelector('.playback_button-svg').classList.remove('hide');
                    b.querySelector('.pause_button-svg').classList.add('hide');
                    video.removeAttribute('controls');
                    video.removeAttribute('controlsList', '');
                    isPlaying = false;
                }

            })

            video.addEventListener('play', () => {
                  b.querySelector('.playback_button-svg').classList.add('hide');
                  b.querySelector('.pause_button-svg').classList.remove('hide');
                  isPlaying = true;
            });

            video.addEventListener('pause', () => {
                  b.querySelector('.playback_button-svg').classList.remove('hide');
                  b.querySelector('.pause_button-svg').classList.add('hide');
                  isPlaying = false;
            });

            video.addEventListener('ended', () => {
                  b.querySelector('.playback_button-svg').classList.remove('hide');
                  b.querySelector('.pause_button-svg').classList.add('hide');
                  isPlaying = false;
            });
        }
    })
}
