const grids = {
  // Products header grid
    'default': {
        fillGrid: 'left',
        fillColor: '0',
        animate:'parallax',
        grid: [
        [20, 0, 14, 135, 20, 0],
        [0, 0, 110, 0, 0],
        [15, 0, 10, 15, 20],
        [0, 20, 0, 120, 10, 20],
        [110, 0, 17, 0, 0, 50],
        [0, 0, 5, 0, 0],

        ],
    },
  // Products header grid
    'product': {
        scroll: true,
        fade: true,
        play: true,
        fillGrid: 'right',
        fillColor: '0',
        animate: 'fadeFrom',
        grid: [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 40, 0, 0, 0, 90],
        [0, 80, 0, 0, 40, 0, 0],
        [0, 0, 70, 15, 0, 5, 60],
        [0, 0, 0, 0, 40, 0, 90],
        [0, 0, 40, 0, 20, 95, 70],
        [0, 0, 10, 60, 20, 99],
        ],
    },
    'product-bg': {
        fade: true,
        play: true,
        fillGrid: 'right',
        fillAlpha: '00',
        animate: 'fadeFrom',
        grid: [
        [100, 100, 100, 100, 100, 100, 100],
        [100, 100, 0, 100, 100, 100, 0],
        [100, 0, 100, 100, 40, 100, 100],
        [100, 100, 0, 0, 100, 0, 0],
        [100, 100, 100, 100, 0, 0, 0],
        [100, 100, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0],
        ],
    },
    'category': {
        scroll: true,
        fade:true,
        play: true,
        animate:'fadeTo',
        grid: [
        [120, 155, 10, 0, 0, 0, 0, 220, 0],
        [0, 0, 20, 0, 0, 0, 120, 240, 240],
        [0, 10, 125, 20, 0, 0, 110, 20, 10],
        [130, 5, 110, 0, 0, 0, 130, 0, 110],
        [110, 140, 0, 0, 0, 0, 120, 110, 190],
        [120, 0, 0, 120, 0, 140, 160, 5, 0],
        ],
    },
    'single': {
        scroll: true,
        fade:true,
        play: true,
        animate:'fadeTo',
        fillGrid: 'right',
        grid: [
        [0, 0, 140, 130 ],
        [0, 35, 10, 25],
        [0, 15, 20, 120],
        [0, 0, 10, 0 ],
        [0, 0, 110, 5 ],
        [0, 0, 0, 110, 0],
        ],
    },
    'sub': {
        animate:'parallax',
        fillAlpha: '00',
        fillColor: '0',
        grid: [
        [0, 5, 0, 0, 0, 0, 0, 0],
        [115, 10, 0, 0, 0, 0, 0, 0],
        [0, 105, 0, 0, 0, 0, 0, 0, 0],
        [0, 5, 0, 0, 0, 0, 0, 0],
        [0, 3, 0, 0, 120, 0, 0, 110, 40],
        [0, 0, 220, 0, 0, 0, 100, 100, 100],
        [120, 0, 0],
        ],
    },
    'sep': {
        animate: 'parallax',
        grid: [

        [0, 135, 120, 0, 0, 115, 20, 130],
        [0, 25, 0, 0, 0, 115, 20, 130],
        [0, 5, 12, 0, 0, 115, 20, 130],
        [0, 14, 20, 0, 0, 115, 20, 130],
        [0, 0, 0, 0],
        [0, 0, 105],
        [0,125, 0, 0],
        [0,125, 0, 0],
        ],
    },
    'product-list': {
        fillGrid:'bottom',
        animate: 'parallax',
        grid: [
        [0, 0, 0, 125, 5, 135, 0, 120, 205, 0, 20],
        [0, 0, 0, 0, 0, 0, 140, 10, 0, 12],
        [0, 0, 115, 5, 0, 0, 0, 130, 0, 3],
        [0, 0, 0, 110, 0, 105, 15, 5, 0, 0],
        ],
    },
    'front': {
        scroll: true,
        fade: true,
        play: true,
        animate:'fadeTo',
        grid: [
        [200, 200, 200, 0, 0, 0, 30, 120, 240],
        [200, 190, 200, 0, 0, 230, 0, 0, 0],
        [200, 200, 200, 0, 0, 0, 260, 230, 0],
        [200, 160, 0, 0, 0, 240, 240, 0, 0],
        [200, 190, 200, 199, 10, 20, 220, 240, 0],
        [20, 0, 150, 5, 0, 120, 20, 0, 140, 20],
        ],
    },
    'front-reverse': {
        scroll: true,
        fade: true,
        play: true,
        animate:'fadeTo',
        grid: [
        [240, 120, 30, 0, 0, 0, 200, 200, 200],
        [0, 140, 250, 230, 0, 0, 200, 190, 200],
        [0, 230, 260, 0, 0, 0, 200, 200, 200],
        [0, 0, 270, 240, 0, 0, 0, 160, 200],
        [0, 240, 220, 10, 20, 199, 200, 190, 200],
        [20, 140, 0, 20, 120, 0, 5, 150, 0, 20],
        ],
    },
    'menu': {
        scroll: false,
        animate: 'toleft',
        fillGrid: 'left',
        grid: [
        //[100, 100],

        [100, 100, 100, 100],
        [100, 100, 80, 100],
        [85, 60, 85, 140],
        [100, 50, 60, 100],
        [100, 30, 50, 100],
        [100, 160, 20, 70],
        [100, 100, 50, 100],
        ],
    },
    'full': {
        animate: 'toleft',
        grid: [
        //[100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 60, 100, 80, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100],
        // [ 190, 190, 190, 190, 190, 190, 190, 100, 100, 100, 100, 100],
        ],
    },
}

export default grids;
