import domReady from '@roots/sage/client/dom-ready';
import {heroes} from "@scripts/components/heroes.js";
import {details} from "@scripts/components/details.js";
import {searchButton} from "@scripts/components/search.js";
import {videoplayer} from "@scripts/components/video-player.js";
import {navHeight, navSticky} from "@scripts/components/nav.js";
import {scroll} from "@scripts/tools/scroll.js";
import {resize} from "@scripts/tools/resize.js";
import {modal} from "@scripts/components/modal.js";
import Hexagones from "@scripts/lib/hexagones/Hexagones.js";
import {modals} from "@scripts/components/modal.js";
import {gallery} from "@scripts/components/gallery.js";

/**
 * Application entrypoint
 */
domReady(async() => {
    const nav_sticky = navSticky('.header__main');

  /** Bind scroll behabior */
    scroll(
        nav_sticky
    );
  /** Bind resize behavior */
  resize(
      navHeight
  )

  heroes();
  details();
  searchButton();
  videoplayer();
  gallery();
  modal();

  document.querySelectorAll('.hexagones-canvas').forEach((element) => {

        new Hexagones(element);
    });
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(console.error);
}
