export default {
    isTouch() {
        return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },
    scrollEvent() {
        return this.isTouch() ? 'scroll' : 'scroll';
    }
};

