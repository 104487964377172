import grids from '@scripts//data/grids';
import gridsTablet from '@scripts//data/grids-tablet';
import gridsLaptop from '@scripts//data/grids-laptop';
import gridsMobile from '@scripts/data/grids-mobile';
import Hexa from '@scripts/lib/hexagones/components/Hexa';
import Helpers from "@scripts/tools/Helpers.js";

export default class Hexagones {
    constructor(canvas)
    {



        this.canvas = canvas;
        this.currentDelta;
        this.context = this.canvas.getContext('2d');
      //this.context = this.canvas.getContext('webgl');
        this.context.globalCompositeOperation = 'hard-light';

        if (!this.canvas) {
            return;
        }



        this.fade = true;
        this.scrollY = 0;
        this.goUp = false;

        this.gridType = this.canvas.getAttribute('data-grid');
        this.reverse = this.canvas.getAttribute('data-reverse');


        if (!this.gridType) {
            this.gridType = 'default';
        }

      // Set default animation
      //this._animation = this.fadeTo;

      // set default grid values
        this.originalProps = {
            fillGrid: true,
            primary: '#fff',
            secondary: '#111b2e',
            third: '#3264ca',
            scroll: false,
            fade: false,
        }

      // Select gird by device size
      // @todo find a better way to have multiple grids
        if (window.innerWidth < 768) {
            this.originalProps = Object.assign(this.originalProps,gridsMobile[this.gridType]);
          console.log(this.gridType);
        } else if (window.innerWidth < 1440) {
            this.originalProps = Object.assign(this.originalProps,gridsTablet[this.gridType]);
        } else if (window.innerWidth < 1540) {
            this.originalProps = Object.assign(this.originalProps,gridsLaptop[this.gridType]);
        } else {
            this.originalProps = Object.assign(this.originalProps,grids[this.gridType]);
        }


      // Clone props to be able to alter the props without touching the grid reference
        this.props = JSON.parse(JSON.stringify(this.originalProps));

        if (this.reverse) {
            this.props.isreversed = true;
        }

        // Fix the performance issue when the notification full screen is on
        if (document.querySelectorAll('.notification__fullscreen').length > 0) {
            document.body.addEventListener('update-glides', () => {
                setTimeout(this.appLaunch.bind(this), 50);
            })
        } else {
            // Wait the css render to fix the canvas height
            setTimeout(this.appLaunch.bind(this), 250);
        }


      // Listen custom animate event on canvas to start animation
        this.canvas.addEventListener('animate', (event) => {
            this.resize();
            this.prepareGrid();
            this.drawGrid();
            this.prepareAnimation();

            if (this._animation) {
                window.requestAnimationFrame(this.requestAnimation.bind(this));
            }

            if (event.detail && event.detail._callback) {
                this._callback = event.detail._callback;
            }
        });

      // Listen custom reverseanimate event on canvas to start animation reverse (ex: closing modal)
        this.canvas.addEventListener('reverseanimate', (event) => {
            this.resize();

            if (event.detail && event.detail._callback) {
                this._callback = event.detail._callback;
                this._callback();
            }
        });

      // Force scroll movement
        if (this.canvas.getAttribute('data-scroll')) {
            this.props.scroll = this.canvas.getAttribute('data-scroll');
        }

        if (this.props.scroll) {
            this.isScrolling = true;
            this.scrollListener();
        }

        if (!Helpers.isTouch()) {
            window.addEventListener('resize', () => {
                this.resize();
                this.prepareGrid();
                this.drawGrid();
                if (this.props.scroll) {
                    this.scroll();
                }
            })
        } else {
            // @todo to replace when screen.orientation.addEventListener('change') will be compatible with Safari ios
            window.addEventListener('orientationchange', () => {
                setTimeout(() => {

                    this.resize();
                    this.prepareGrid();
                    this.drawGrid();
                    if (this.props.scroll) {
                        this.scroll();
                    }
                    this.render(true);
                }, 250);
            })
        }

    }

    appLaunch()
    {
        this.resize();
        this.prepareGrid();

        if (this.reverse) {
            this.props.isreversed = true;
        }


        this.drawGrid();
        this.prepareAnimation();
    }

    getRandomHexa(_color, _alpha)
    {

        if (typeof _alpha === 'undefined') {
            const alphaList = [
            '03',
            '06',
            '08',
            '12',
            '14',
            '16',
            ];
            _alpha = alphaList[Math.floor(Math.random() * alphaList.length)];
        }

        if (typeof _color === 'undefined') {
            _color = Math.round(Math.random());
        }

        const randomColorAlpha = _color + '' + _alpha;
        //const randomColorAlpha = randomColor + '' +( Math.floor(Math.random() * 10) + 1);

        return parseInt(randomColorAlpha);
    }

  /*
   * Generate the grid (cut the grid from the center or add random hexagone to fill the sides)
   */
    prepareGrid()
    {
      // fill array with missing Hexagones (randomly)

        this.addedCount = 0;

        if (this.props.fillGrid) {
            if (this.props.fillGrid == 'right') {
                this.maxColumn;
                this.originalProps.grid.forEach((row) => {
                    if (row.length < this.maxColumn) {
                        this.addedCount = 0;
                        for (let i = 0; i < (this.maxColumn - row.length); i++) {
                            row.push(this.getRandomHexa(this.props.fillColor, this.props.fillAlpha));
                            this.addedCount++;
                        }
                    }
                })
            } else if (this.props.fillGrid == 'left') {
                this.maxColumn;
                this.originalProps.grid.forEach((row) => {
                    if (row.length < this.maxColumn) {
                        this.addedCount = 0;
                        for (let i = 0; i < (this.maxColumn - row.length); i++) {
                            row.unshift(this.getRandomHexa(this.props.fillColor, this.props.fillAlpha));
                            this.addedCount++;
                        }
                    }
                })
            } else if (this.props.fillGrid == 'bottom') {
                if ( this.originalProps.grid.length < this.maxRow) {
                  // generate now row

                    const duplicateTotal = Math.floor(this.maxRow / this.originalProps.grid.length);
                    console.log(this.maxRow / this.originalProps.grid.length,this.originalProps.grid.length,this.maxRow , duplicateTotal)

                    for (let i = 0; i < duplicateTotal-1; i++) {
                        this.originalProps.grid = this.originalProps.grid.concat(this.originalProps.grid);
                    }

                    // Remove last row to be sure
                    this.originalProps.grid.pop();


                    /*
                    let row = [];
                    for (let i = 0; i < (this.maxColumn - this.originalProps.grid[0].length); i++) {
                        row.push(this.getRandomHexa(this.props.fillColor, this.props.fillAlpha));
                    }
                    this.originalProps.grid.push(row);*/
                }

                this.originalProps.grid.forEach((row) => {
                    if (row.length < this.maxRow) {
                        this.addedCount = 0;
                        for (let i = 0; i < (this.maxColumn - row.length); i++) {
                            row.unshift(this.getRandomHexa(this.props.fillColor, this.props.fillAlpha));
                            this.addedCount++;
                        }
                    }
                })
            } else {
                this.maxColumn += 2;
              // Fill both side if not specified
                this.originalProps.grid.forEach((row) => {
                    if (row.length < this.maxColumn) {
                        const toAdd = Math.floor((this.maxColumn - row.length) / 2);
                        this.addedCount = 0;
                        for (let i = 0; i < toAdd; i++) {
                            row.push(this.getRandomHexa(this.props.fillColor, this.props.fillAlpha));
                            row.unshift(this.getRandomHexa(this.props.fillColor, this.props.fillAlpha));

                            this.addedCount += 1;
                        }
                    }
                })
            }
        }

        this.props = JSON.parse(JSON.stringify(this.originalProps));
    }

  /**
   * Prepare animation, change props to prepare animation
   */

    prepareAnimation()
    {
        switch (this.props.animate) {
            case 'toleft':
                this.currentColumn = this.props.grid.length;
                this.currentRow = 0;
                this.startTime = null;
                this.graphics.flat().forEach((hexa) => {
                    const _x = hexa.props.x + (window.innerWidth);
                    hexa.update({
                        x: _x,
                        startX: _x,
                        alpha: hexa.props.finalAlpha,
                        step: _x / hexa.props.x,
                    });
                })
                this._animation = this.toLeft;
            break;
            case 'fadeFrom' :
                this.graphics.flat().forEach((hexa) => {
                    hexa.update({
                        alpha: 100,
                    });
                })

                this._animation = this.fadeFrom;
                this.render(true);
                if (this.props.play) {
                        setTimeout(() => {
                            window.requestAnimationFrame(this.requestAnimation.bind(this))
                          }, 150)
                }
            break;
            case 'breathe':
                this.graphics.flat().forEach((hexa) => {
                    hexa.update({
                        fading: false,
                    });
                })

                this._animation = this.breathe;
                window.requestAnimationFrame(this.requestAnimation.bind(this));
            break;
            case 'fadeTo':
                this.graphics.flat().forEach((hexa) => {
                    hexa.update({
                        alpha: (hexa.props.alpha === 100) ? hexa.props.alpha : 0,
                    });
                })

                this._animation = this.fadeTo;
                if (this.props.play) {
                      window.requestAnimationFrame(this.requestAnimation.bind(this))
                }
            break;
            case 'parallax':
                this.graphics.flat().forEach((hexa) => {
                    hexa.update({
                        y: hexa.props.y + this.canvas.height / 4,
                        speed: hexa.props.speed * 150,
                    });
                })


                window.addEventListener('scroll', () => {
                      this.parallax();
                })
                this.parallax();
            break;
            default:
                this.render(true);
            break;
        }
    }

    resize()
    {
        this.angle = Math.radians(180 / 6);

        this.hexaSize = 138.5; // Hexa ~ 240px x 278px

        this.windowWidth = window.innerWidth || document.documentElement.clientWidth;
        //this.hexaSize = this.hexaSize/window.devicePixelRatio;
      // desktop
        if (this.windowWidth < 1440) {
            this.hexaSize = 100; // Hexa ~ 240px x 278px
        }

        this.hexaWidth = Math.sqrt(3) * this.hexaSize;
        this.hexaHeight = 2 * this.hexaSize;

        this.vSpacing = this.hexaHeight * 3 / 4;

        const parent = this.canvas.parentNode.getBoundingClientRect();

        this.maxColumn = Math.ceil(parent.width / this.hexaWidth);
        this.maxRow = Math.ceil(parent.height / this.hexaHeight);

        this.canvas.width = this.maxColumn * this.hexaWidth;
        let transform = 'translateY(-' + this.hexaHeight / 2 + 'px)';
        const translateX = (this.canvas.width - parent.width) / 2;

        transform += ' translateX(-' + translateX + 'px)';
        this.canvas.style.transform = transform;

        this.canvas.height = parent.height + this.hexaWidth;

        if (this.windowWidth > 600 && this.windowWidth < 1440) {
            this.canvas.height += 5;
        }

        this.context.width = this.canvas.width;
        this.context.height = this.canvas.height;
        this.context.translate(0.5, 0.5);
        this.context.imageSmoothingEnabled = true;

        // Rebuild grid from original props
        this.props = JSON.parse(JSON.stringify(this.originalProps));

    }

    getColor(props, alpha)
    {
      // Return the third color if > 200 and color exists else return secondary
        if (alpha > 200 && props.third) {
            return props.third;
        } else if (alpha > 100) {
            return props.secondary;
        } else {
            return props.primary;
        }
    }

    drawGrid()
    {
        this.graphics = [];

      // Place the first element on the border max left
        let x = 0;
        let outside = 0;
      // Mobile size
        if (window.innerWidth <= 1920) {
            x = -this.hexaWidth / 2;
        } else {
            outside = (this.canvas.width - 1920);
            x = outside / 2;
        }


        if (this.props.fillGrid != 'right') {
            if (outside < 0) {
                x = outside / 2;

                if ((outside / 2 - (this.hexaWidth * this.addedCount)) > 0) {
                    x -= this.hexaWidth / 2;
                    document.body.style.setProperty('--home-hexa-x', `${this.hexaWidth / 2}px`)
                }
            } else {
                x = 0;

                document.body.style.setProperty('--home-hexa-x', '0px')
                if ((outside / 2 - (this.hexaWidth * this.addedCount)) > 0) {
                    x -= this.hexaWidth / 2;
                    document.body.style.setProperty('--home-hexa-x', `${this.hexaWidth / 2}px`)
                }
            }
        }


        let y = this.hexaHeight / 2;

        let startX = x;
        if (this.windowWidth > 600 && this.windowWidth < 1440) {
            startX = x + 5;
        }
        const startY = y;

        const maxRow = this.props.grid.length;

        for (let row = 0; row < maxRow; row++) {
            let rows = [];

            const maxColumn = this.props.grid[row].length;

            for (let column = maxColumn - 1; column >= 0; column--) {
                x = startX + (column * this.hexaWidth) + ((row % 2) * this.hexaWidth / 2);

                if (this.props.isreversed) {
                    x = startX + (column * this.hexaWidth) - ((row % 2) * this.hexaWidth / 2);
                }

                y = startY + (row * this.vSpacing);
                let alpha;
                let color;

              // Set current

                if (this.props.grid[row][column] > 200) {
                    alpha = this.props.grid[row][column] - 200;
                } else if (this.props.grid[row][column] > 100) {
                    alpha = this.props.grid[row][column] - 100;
                } else {
                    alpha = this.props.grid[row][column];
                }

                color = this.getColor(this.props, this.props.grid[row][column])


                let graphic = new Hexa(this.canvas, x, y, this.hexaSize, row, column, alpha, color);

                if (this.reverse) {
                    graphic.targetColor = graphic.rgb(this.getColor(this.originalProps, this.originalProps.grid[row][column]));
                }

                rows.push(graphic)
            }
            this.graphics.push(rows);
        }
        this.render(true);
    }

    render(force = false)
    {
      // Render only if canvas is visible
        if (force || this.isInViewport() || this.gridType == 'menu') {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.graphics.forEach((rows) => {
                rows.forEach(hexa => hexa.draw())
            })
        }

        if (force) {
            this.canvas.dispatchEvent(new CustomEvent('forcerender'));
        }
    }

    requestAnimation(delta)
    {
        this.isRunning = false;

        if (!this.startTime) {
            this.duration = 850;
            this.startTime = delta;
            this.elapsedTime = 0;
        }

        const runtime = delta - this.startTime;
        const relativeProgress = runtime / this.duration;
        const easeDelta = this.ease(relativeProgress);

        this._animation(easeDelta);

        if (this.isRunning) {
            this.render(true);
            window.requestAnimationFrame(this.requestAnimation.bind(this))
        } else {
          // end of animation, trigger callback
            if (this._callback) {
                this._callback();
            }
            this.startTime = null;
        }
    }

    breathe(easeDelta)
    {

        this.graphics.flat().forEach((hexa) => {

            if (!hexa.props.fading) {
                const alpha = (hexa.props.alpha + (easeDelta * (hexa.props.speed / 25)));
                if (alpha <= hexa.props.finalAlpha) {
                    hexa.update({alpha: alpha});
                } else {
                    hexa.update({fading: true});
                }
            } else {
                const alpha = (hexa.props.alpha - (easeDelta * (hexa.props.speed / 25)));
                if (alpha >= 0) {
                    hexa.update({alpha: alpha});
                } else {
                    const _alpha = this.getRandomHexa();
                    const finalAlpha = (_alpha > 200) ? _alpha - 200 : (_alpha > 100) ? _alpha - 100 : _alpha;
                    hexa.update({
                        fading: false,
                        finalAlpha: finalAlpha,
                        color: this.getColor(this.props, _alpha),
                        speed: (Math.floor(Math.random() * 30) + 10) / 24,
                    });
                }
            }

            this.isRunning = true;
        })
    }

    fadeTo(easeDelta)
    {
        this.graphics.flat().filter(
            hexa => hexa.props.alpha < hexa.props.finalAlpha
        ).forEach((hexa) => {

            const alpha = (hexa.props.alpha + (easeDelta * (hexa.props.speed / 10)));
            hexa.update({alpha: alpha});
            this.isRunning = true;
        })
    }

    fadeFrom(easeDelta)
    {
        this.graphics.flat().filter(
            hexa => hexa.props.alpha > hexa.props.finalAlpha
        ).forEach((hexa) => {
            const alpha = (hexa.props.alpha - (easeDelta * (hexa.props.speed / 5)));

            hexa.update({alpha: alpha});
            this.isRunning = true;
        })
    }

    ease(x)
    {
        return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
    }

    toLeft(easeDelta)
    {

        this.graphics.flat().filter(
            hexa => hexa.props.x != hexa.originalProps.x
        ).forEach((hexa) => {
            let _x = hexa.props.x - (175 * easeDelta);
            //let _x = hexa.props.x - (200);
            if (_x < hexa.originalProps.x) {
                _x = hexa.originalProps.x;
            }

            hexa.update({x: _x});
            this.isRunning = true;
        })
    }

    scrollListener()
    {

        this.scrollActive = true;
        window.addEventListener('scroll', () => {
            this.scroll();
        })
    }

    scrollDirection()
    {

        const currentScrollY = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScrollY < this.scrollY) {
            this.goUp = false;
        } else if (currentScrollY > this.scrollY) {
            this.goUp = true;
        } else {
            this.isRunning = false;
        }

        this.scrollY = window.pageYOffset || document.documentElement.scrollTop;
    }

    parallax()
    {
        this.scrollDirection();
        this.graphics.flat().forEach((hexa) => {
          //const scroll = this.getScrollPercent();

            if (this.goUp) {
                let _y = hexa.props.y - (hexa.originalProps.speed);

                if (_y > hexa.props.y - this.canvas.height / 4) {
                    hexa.update({y: _y});
                }
            } else {
                let _y = hexa.props.y + (hexa.originalProps.speed);
                if (_y < hexa.props.y + this.canvas.height / 4) {
                    hexa.update({y: _y});
                }
            }

        })
        this.render(this.scrollActive);
    }

    scroll()
    {
        this.scrollDirection();
        const scroll = this.getScrollPercent();

      //const speedIncrease = 200;
      // @todo quick fix to test if graphics are there
        if (this.scrollActive && this.graphics && this.graphics.length > 0) {
            this.graphics.flat().filter(
                (hexa) => hexa.slide
            ).forEach((hexa) => {
                  //let _distance = hexa.props.speed / 10;
                  // Based on original y
                  const increase = Helpers.isTouch() ? 2.5 : 3;

                  let _y = hexa.originalProps.y + (hexa.originalProps.speed * 5 * scroll);
                  let _alpha = hexa.originalProps.finalAlpha - (hexa.originalProps.speed / increase * scroll);

                if (_alpha >= hexa.originalProps.finalAlpha) {
                    _alpha = hexa.originalProps.finalAlpha;
                }

                // Reset _y and alpha on top
                if (scroll === 0) {
                    _alpha = hexa.originalProps.finalAlpha;
                    _y = hexa.originalProps.y;
                } else if (this.goUp) {
                    if (hexa.slide === 'up') {
                            _y = hexa.originalProps.y;
                            _alpha = hexa.originalProps.finalAlpha;
                    }
                } else {
                    if (hexa.slide === 'up') {
                        _y = hexa.originalProps.y;
                        _alpha = hexa.originalProps.finalAlpha;
                    }
                }

                if (this.props.fade) {
                    hexa.update({y: _y, alpha: _alpha});
                } else {
                    hexa.update({y: _y});
                }
            });
            this.render(this.scrollActive);

            this.show = this.graphics.flat().filter(
                (hexa) => hexa.props.alpha > 0 && hexa.props.alpha != 100
            ).length;

            if (this.show == 0) {
                  this.canvas.style.display = 'none';
            } else {
                this.canvas.style.display = 'block';
            }
        }
    }

    getScrollPercent()
    {
        return ((document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100);
    }

    isInViewport()
    {
        const rect = this.canvas.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return (
        ((rect.top) <= windowHeight || rect.bottom <= windowHeight) && rect.bottom > 0
        );
    }

}
