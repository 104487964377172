export const searchButton = () => {
  /*
  * Search button header behavior
  */
    const searchOpenButton = document.querySelector('.btn__search--open');
    if (searchOpenButton) {
        searchOpenButton.addEventListener('click', (event) => {
            event.preventDefault();

          // Close modal if open
            if (document.querySelector('.btn__menu.open button')) {
                document.querySelector('.btn__menu.open button').click();
            }


            let form = document.querySelector('.search__form');
            form.classList.add('open')
            form.querySelector('input[type=search]').focus();
        })

        document.querySelector('.btn__search--close').addEventListener('click', (event) => {
            event.preventDefault();
            let form = document.querySelector('.search__form')
            if (form.classList.contains('open')) {
                form.classList.remove('open')
            }
        })
    }
}
