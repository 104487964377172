const grids = {
  // Products header grid
    'default': {
        fillGrid: 'left',
        fillColor: '0',
        animate:'parallax',
        grid: [
        [20,0, 0, 0, 0, 10, 0],
        [20,140, 30, 130, 10, 0],
        [10,20, 0, 30, 15, 20],
        [20,0, 0, 0, 130, 10, 20],
        [20,0, 0, 20, 0, 20, 50],
        [5,10, 150, 5, 10, 0],
        [10,135, 0, 5, 0, 0],

        ],
    },
  // Products header grid
    'product': {
        scroll: true,
        fade: true,
        play: true,
        fillGrid: 'right',
        fillColor: '0',
        animate: 'fadeFrom',
        grid: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 90],
          [0, 0, 0, 80, 0, 0, 40, 0, 0],
          [0, 0, 0, 0, 0, 15, 0, 5, 60],
          [0, 0, 0, 60, 0, 0, 40, 0, 90],
          [0, 0, 0, 0, 40, 0, 20, 95, 70],
          [0, 0, 0, 0, 10, 60, 20, 99],
        ],
    },
    'product-bg': {
        fade: true,
        play: true,
        fillGrid: 'right',
        fillAlpha: '00',
        animate: 'fadeFrom',
        grid: [
          [100,100, 100, 100, 100, 100, 100, 100, 100,100],
          [100,100, 100, 100, 100, 100, 100, 100, 0],
          [100,100, 100, 0, 100, 100, 40, 100, 100],
          [100,100, 100, 100, 100, 0, 100, 0, 0],
          [100,100, 100, 0, 100, 100, 0, 0, 0],
          [100,100, 100, 100, 0, 0, 0, 0, 0],
          [100,100, 100, 100, 0, 0, 0, 0, 0],
          [100,100, 100, 100, 0, 0, 0, 0, 0],
          [0,0, 0, 0, 0, 0, 0, 0],
        ],
    },
    'category': {
        scroll: true,
        fade:true,
        play: true,
        animate:'fadeTo',
        grid: [
        [120, 130, 20, 110, 130, 0, 0, 220, 0],
        [0, 0, 130, 0, 10, 0, 120, 240, 240],
        [150, 15, 0, 20, 120, 0, 110, 20, 10],
        [170, 0, 15,0 , 0, 0, 130, 0, 110],
        [180, 0, 0, 155, 0, 0, 120, 110, 190],
        [120, 0, 0, 110, 0, 140, 160, 5, 0],
        ],
    },
    'single': {
        scroll: true,
        fade:true,
        play: true,
        animate:'fadeTo',
        fillGrid: 'right',
        grid: [
        [0, 0, 0, 130, 10, 110, 0, 120, 10, 30, 20],
        [0, 0, 10, 10, 0, 120, 120, 105, 10, 30, 20],
        [0, 0, 20, 0, 120, 10, 110, 10, 40, 20],
        [0, 0, 10, 0, 0, 0, 0, 10, 0, 10],
        [0, 0, 110, 25, 0, 0, 0, 130, 0, 10],
        [0, 0, 115, 15, 0, 110, 10, 5, 0, 0],
        ],
    },
    'sub': {
        animate:'parallax',
        fillAlpha: '00',
        fillColor: '0',
        grid: [
          [0, 0, 0, 0, 0, 0],
          [0, 5, 0, 110, 0],
          [0,10, 0, 5, 0, 0],
          [0,0, 210, 115, 0, 0],
          [0,0,5, 0, 205, 0, 0],
          [0,125,0, 10, 0, 0, 210],
          [0,5, 115,0, 0, 0],
          [0,0, 5,15, 0, 0],
        ],
    },
    'sep': {
        animate: 'parallax',
        grid: [
          [0, 10, 120, 0, 0],
          [0, 20,0,125, 0, 15],
          [0, 115, 5, 0, 0, 10, 105, 15],
          [0, 0,0, 0, 105],
          [0, 15,0,115, 20, 0],
          [0, 0,105, 10, 110, 0],
          [0, 0,114, 10, 110, 0],
        ],
    },
    'product-list': {
        fillGrid:'bottom',
        animate: 'parallax',
        grid: [
        [0, 0, 0, 125, 5, 135, 0, 120, 205, 0, 20],
        [0, 0, 0, 0, 0, 0, 140, 10, 0, 12],
        [0, 0, 115, 5, 0, 0, 0, 130, 0, 3],
        [0, 0, 0, 110, 0, 105, 15, 5, 0, 0],
        ],
    },
    'front': {
        scroll: true,
        fade: true,
        play: true,
        animate:'fadeTo',
        grid: [
          [240, 120, 30, 0, 0, 0, 200, 200, 200],
          [0, 140, 250, 230, 10, 0, 200, 190, 200],
          [0,120, 230, 260, 0, 25, 0, 200, 200, 200],
          [0, 105, 20, 10, 20, 199, 230, 190, 200],
          [0, 0, 270, 240, 0, 0, 0, 160, 200],
          [150, 140, 0, 20, 120, 0, 5, 150, 0, 20],
        ],
    },
    'front-reverse': {
        scroll: true,
        fade: true,
        play: true,
        animate:'fadeTo',
        grid: [
        [240, 120, 30, 0, 30, 120, 200, 200, 200],
        [0, 0, 0, 230, 0, 120, 200, 175, 200],
        [10,0, 230, 260, 0, 0, 0, 200, 200, 200],
        [0, 135, 220, 10, 20, 110, 200, 190, 200],
        [120,0, 240, 240, 0, 0, 0, 160, 200],
        [200, 140, 0, 140, 10, 0, 5, 150, 0, 20],

        ],
    },
    'menu': {
        scroll: false,
        animate: 'toleft',
        fillGrid: 'left',
        grid: [
        [ 0,0, 0, 40, 100, 100, 100, 100, 100],
        [ 0,0, 0, 40, 100, 100, 100, 100, 100],
        [ 0,0, 5, 0, 0, 100, 100, 100, 100],
        [ 0,0, 0, 0, 100, 100, 100, 100, 100],
        [ 0,0, 0, 0, 0, 40, 100, 100, 100],
        [ 0,0, 0, 0, 40, 60, 100, 100, 100],
        [0,0, 0, 0, 0, 40, 60, 100, 100],
        ],
    },
    'full': {
        animate: 'toleft',
        grid: [
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        ],
    },
}

export default grids;
