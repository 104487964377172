// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@images/png/view.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@images/jpg/fnherstal-entry.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@images/svg/icon-newsletter.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@images/svg/icon-linkedin-page.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2738.285%27 height=%2733.598%27 viewBox=%270 0 38.285 33.598%27%3E%3Cg transform=%27translate%280.75 0.782%29%27%3E%3Cpath data-name=%27Path 210%27 d=%27M29.825-188.279,18-181.454a2.368,2.368,0,0,1-2.368,0L3.816-188.279a2.368,2.368,0,0,1-1.184-2.05v-13.649a2.368,2.368,0,0,1,1.184-2.05l11.821-6.825a2.367,2.367,0,0,1,2.367,0l11.821,6.825a2.367,2.367,0,0,1,1.184,2.05v13.649A2.368,2.368,0,0,1,29.825-188.279Z%27 transform=%27translate%28-2.632 213.17%29%27 fill=%27none%27 stroke=%27%230e1533%27 stroke-width=%271.5%27/%3E%3Cline x2=%2713.445%27 transform=%27translate%2820.59 16.017%29%27 fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-width=%277%27/%3E%3C/g%3E%3C/svg%3E%0A", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@images/svg/dot-hexa-dark.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("@images/svg/dot-hexa-light.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("@images/svg/icon-search.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("@images/svg/dot-hexa-open.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%275%27 height=%275%27 viewBox=%270 0 5 5%27%3E%3Ccircle id=%27Ellipse_6%27 data-name=%27Ellipse 6%27 cx=%272.5%27 cy=%272.5%27 r=%272.5%27 fill=%27%23d1d1d1%27/%3E%3C/svg%3E%0A", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("@images/svg/back-square.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("@images/svg/back-line.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_12___ = new URL("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"66.25\" height=\"60.661\" viewBox=\"0 0 66.25 60.661\"><g id=\"Component_4_1\" data-name=\"Component 4 – 1\" transform=\"translate%280.75 0.801%29\"><path id=\"Path_210\" data-name=\"Path 210\" d=\"M56.623-167.278,33.153-154.7a5.017,5.017,0,0,1-4.7,0L4.982-167.278a4.319,4.319,0,0,1-2.35-3.78v-25.164A4.318,4.318,0,0,1,4.982-200l23.47-12.582a5.014,5.014,0,0,1,4.7,0L56.623-200a4.317,4.317,0,0,1,2.35,3.78v25.164A4.319,4.319,0,0,1,56.623-167.278Z\" transform=\"translate%28-2.632 213.17%29\" fill=\"none\" stroke=\"%23fff\" stroke-width=\"1.5\"/><line id=\"Line_62\" data-name=\"Line 62\" x2=\"13.445\" transform=\"translate%2848.555 29.53%29\" fill=\"none\" stroke=\"%2313192b\" stroke-linecap=\"round\" stroke-width=\"7\"/></g></svg>", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_13___ = new URL("@images/svg/double-hexa.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_14___ = new URL("@images/svg/info-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_15___ = new URL("@images/svg/arrow-faq.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_12___);
var ___CSS_LOADER_URL_REPLACEMENT_13___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_13___);
var ___CSS_LOADER_URL_REPLACEMENT_14___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_14___);
var ___CSS_LOADER_URL_REPLACEMENT_15___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_15___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/** Base style */
/* stylelint-disable */
/* stylelint-enable */
/*** The new CSS Reset - version 1.0.0 (last updated 8.7.2021) ***/
/* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
*:where(:not(iframe):not(canvas):not(img):not(svg):not(video):not(svg *)) {
  all: unset;
  display: revert;
}
/* Preferred box-sizing value */
/* stylelint-disable */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* stylelint-enable */
/* Remove list styles (bullets/numbers) */
ol,
ul {
  list-style: none;
}
/* For images to not be able to exceed their container */
img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}
/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}
/** Media queries size */
:root {
  --f-weight: 200;
  --f-thin: 100;
  --f-extralight: 200;
  --f-light: 300;
  --f-regular: 400;
  --f-medium: 500;
  --f-semibold: 600;
  --f-bold: 700;
  --f-extrabold: 800;
  --f-black: 900;
  --f-family: "oswald", sans-serif;
  --f-title: "oswald", sans-serif;
  --f-style: normal;
  --f-size: calc((16px) + 0.1vw);
  --f-lh: normal;
  --f-h1: 5em;
}
:root {
  --rgb-primary: 19, 25, 43;
  --rgb-bg: 255, 255, 255;
  --c-primary: #223493;
  --c-primary-dark: #13192B;
  --c-secondary: #05C4F2;
  --c-link: var(--c-primary-dark);
  --c-link-hover: var(--c-primary);
  --c-bg: #fff;
  --c-grey: #c1c1c1;
  --c-grey-light: #EEF2F3;
  --c-danger: #dc3545;
  --c-success: #28a745;
  --c-warning: #ffc107;
  /** Text colors */
  --c-text: var(--c-primary-dark);
  --c-title: var(--c-primary);
  /** Btn colors */
  --c-btn-text: var(--c-primary);
  --c-btn-bg: var(--c-bg);
  --c-btn-text-hover: var(--c-bg);
  --c-btn-bg-hover: var(--c-primary);
  --branding-height: 6.5rem;
  --branding-width: var(--branding-height);
  --h-header: 50vh;
  --spacer: calc(1rem + 0.25vw);
  --small-spacer: calc(1rem + 0.25vw);
  --bezier: cubic-bezier(.26, 1.03, .62, .9);
  --w-max: 1920px;
  --h-max: 1080px;
  --min-outside: calc(var(--spacer) * 2);
  --max-outside: calc((100vw - var(--w-max)) / 2);
  --arrow-width: 4.5rem;
  --first-col: calc(var(--w-max) * 0.15 + var(--max-outside));
  --poly-spacer: 3rem;
  --h-heroes: 95vh;
  --p-outside: max(var(--min-outside), var(--max-outside));
  /**
  Animation
   */
  --d-poly: 12s;
}
@media (min-width: 768px) {
  :root {
    --min-outside: calc(var(--spacer) * 2);
    --branding-height: 7.5rem;
    --poly-spacer: 2.24rem;
    --h-heroes: 95vh;
  }
}
@media (min-width: 1540px) {
  :root {
    --min-outside: calc(var(--spacer) * 8);
    --branding-height: 12.5rem;
    --poly-spacer: 3.4rem;
  }
}
@media (min-width: 1920px) {
  :root {
    --branding-width: 200px;
    --small-spacer: 2.5rem;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.asset svg,
.asset img {
  display: block;
}
*::selection {
  background: var(--c-primary);
}
html {
  scroll-behavior: smooth;
}
b,
strong {
  font-weight: 500;
}
dfn,
em,
i,
cite {
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  font-weight: var(--f-black);
  color: var(--c-title);
  font-family: var(--f-title);
}
h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child),
h4:not(:last-child),
h5:not(:last-child),
h6:not(:last-child) {
  padding-bottom: calc(var(--spacer) * 1);
}
h1 {
  font-size: calc(2.55rem + 1.2vw);
}
h2 {
  font-size: calc(2rem + 1vw);
}
h3 {
  font-size: calc(1.2em + 0.25vw);
}
h4 {
  font-size: calc(1.1em + 0.25vw);
}
h5 {
  font-size: calc(1.15em + 0.25vw);
}
h6 {
  font-size: calc(1rem + 0.25vw);
}
div.text__bigger:not(:last-child),
div.text__medium:not(:last-child),
p:not(:last-child) {
  margin: 0 0 calc(var(--spacer) * 1);
}
ul,
ol {
  margin: 0 0 1.5em;
  padding: 0 0 0 1em;
}
ul li {
  list-style: disc;
}
ol {
  counter-reset: ol-counter;
  list-style: none;
  padding-left: 2.5rem;
}
ol li {
  --size: 1.6em;
  counter-increment: ol-counter;
  position: relative;
}
ol li:not(:last-child) {
  padding-bottom: 1em;
}
ol li::before {
  content: counter(ol-counter);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--size) / 2);
  width: var(--size);
  height: var(--size);
  left: calc(-1 * var(--size) - 0.5em);
  top: 0.05em;
  color: var(--c-primary);
  background-color: var(--c-bg);
}
pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}
code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 1.2rem;
}
abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}
mark,
ins {
  background: #fff9c0;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.content a:not(.link__btn):not(.link--cta) {
  color: var(--c-secondary);
  font-weight: var(--f-regular);
}
.content a:not(.link__btn):not(.link--cta):hover, .content a:not(.link__btn):not(.link--cta):focus {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
a,
button {
  cursor: pointer;
}
.text__bigger {
  font-weight: var(--f-light);
  line-height: 1.1;
  font-size: 1.4em;
}
@media (min-width: 768px) {
  .text__bigger {
    font-size: 1.8em;
  }
}
.text__bigger strong {
  font-weight: var(--f-medium);
}
.text__medium {
  font-size: 1.35em;
  font-weight: var(--f-light);
  line-height: 1.2;
}
.text__medium strong {
  font-weight: var(--f-medium);
}
sup {
  font-size: 0.6em;
  vertical-align: super;
}
sub {
  font-size: 0.6em;
  vertical-align: sub;
}
body {
  color: var(--c-text);
  background-color: var(--c-bg);
  -webkit-font-smoothing: antialiased;
  font-family: var(--f-family);
  font-style: var(--f-style);
  font-size: var(--f-size);
  line-height: var(--f-lh);
  font-weight: var(--f-weight);
  margin: 0;
}
.anim.fade {
  animation-name: fade;
  animation-delay: 0s;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: paused;
}
.anim.fade.appear {
  animation-play-state: running;
}
.anim.appear::before {
  animation-play-state: running;
}
.anim.appear::after {
  animation-play-state: running;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes polytop {
  0% {
    -webkit-clip-path: polygon(0 0, 36% 20px, 76% -15px, 100% 20px, 100% 140%, 0 140%);
            clip-path: polygon(0 0, 36% 20px, 76% -15px, 100% 20px, 100% 140%, 0 140%);
  }
  50% {
    -webkit-clip-path: polygon(0 10px, 36% -10px, 76% 10px, 100% 10px, 100% 140%, 0 140%);
            clip-path: polygon(0 10px, 36% -10px, 76% 10px, 100% 10px, 100% 140%, 0 140%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 36% 20px, 76% -15px, 100% 20px, 100% 140%, 0 140%);
            clip-path: polygon(0 0, 36% 20px, 76% -15px, 100% 20px, 100% 140%, 0 140%);
  }
}
@media (min-width: 1540px) {
  @keyframes polytop {
    0% {
      -webkit-clip-path: polygon(0 0, 36% 80px, 76% -30px, 100% 60px, 100% 140%, 0 140%);
              clip-path: polygon(0 0, 36% 80px, 76% -30px, 100% 60px, 100% 140%, 0 140%);
    }
    50% {
      -webkit-clip-path: polygon(0 20px, 36% -20px, 76% 60px, 100% 30px, 100% 140%, 0 140%);
              clip-path: polygon(0 20px, 36% -20px, 76% 60px, 100% 30px, 100% 140%, 0 140%);
    }
    100% {
      -webkit-clip-path: polygon(0 0, 36% 80px, 76% -30px, 100% 60px, 100% 140%, 0 140%);
              clip-path: polygon(0 0, 36% 80px, 76% -30px, 100% 60px, 100% 140%, 0 140%);
    }
  }
}
@keyframes polybottom {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 99%, 43% 97%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 99%, 43% 97%, 0 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 102%, 43% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 102%, 43% 100%, 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 100%, 43% 98%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 100%, 43% 98%, 0 100%);
  }
}
@keyframes polyfull {
  0% {
    -webkit-clip-path: polygon(0 0, 36% 10px, 76% -10px, 100% 20px, 100% 99%, 80% calc(100% - 20px), 40% calc(99% + 10px), 0 100%);
            clip-path: polygon(0 0, 36% 10px, 76% -10px, 100% 20px, 100% 99%, 80% calc(100% - 20px), 40% calc(99% + 10px), 0 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 10px, 36% -5px, 76% 15px, 100% 0, 100% 99%, 80% calc(99% - 10px), 40% calc(100% + 10px), 0 100%);
            clip-path: polygon(0 10px, 36% -5px, 76% 15px, 100% 0, 100% 99%, 80% calc(99% - 10px), 40% calc(100% + 10px), 0 100%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 36% 10px, 76% -10px, 100% 20px, 100% 99%, 80% calc(100% - 20px), 40% calc(99% + 10px), 0 100%);
            clip-path: polygon(0 0, 36% 10px, 76% -10px, 100% 20px, 100% 99%, 80% calc(100% - 20px), 40% calc(99% + 10px), 0 100%);
  }
}
@media (min-width: 1540px) {
  @keyframes polyfull {
    0% {
      -webkit-clip-path: polygon(0 0, 36% 50px, 76% -30px, 100% 60px, 100% 100%, 80% calc(100% + 50px), 35% calc(100% - 40px), 0 100%);
              clip-path: polygon(0 0, 36% 50px, 76% -30px, 100% 60px, 100% 100%, 80% calc(100% + 50px), 35% calc(100% - 40px), 0 100%);
    }
    50% {
      -webkit-clip-path: polygon(0 20px, 36% -10px, 76% 50px, 100% 30px, 100% 100%, 80% calc(100% + 20px), 35% calc(100% - 20px), 0 100%);
              clip-path: polygon(0 20px, 36% -10px, 76% 50px, 100% 30px, 100% 100%, 80% calc(100% + 20px), 35% calc(100% - 20px), 0 100%);
    }
    100% {
      -webkit-clip-path: polygon(0 0, 36% 50px, 76% -30px, 100% 60px, 100% 100%, 80% calc(100% + 50px), 35% calc(100% - 40px), 0 100%);
              clip-path: polygon(0 0, 36% 50px, 76% -30px, 100% 60px, 100% 100%, 80% calc(100% + 50px), 35% calc(100% - 40px), 0 100%);
    }
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeback {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
@keyframes fadebg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}
@keyframes left {
  from {
    transform: translateX(120%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes right {
  from {
    transform: translateX(-120%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes top {
  from {
    transform: translateY(120%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes bottom {
  from {
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0%);
  }
}
.open-modal {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 35 40, pointer;
  text-indent: -9999px;
}
table {
  margin-bottom: calc(var(--spacer) * 2);
  width: 100%;
}
table tr {
  border-bottom: 1px solid rgba(var(--rgb-primary), 0.2);
}
table th,
table td {
  padding: var(--spacer) 0;
}
table th span {
  text-transform: uppercase;
  font-weight: var(--f-bold);
}
/** Layouts */
#main {
  background-color: var(--c-bg);
  position: relative;
  z-index: 2;
}
div .row:not(.row--full),
section .row:not(.row--full) {
  padding-left: var(--p-outside);
  padding-right: var(--p-outside);
}
section:not(.header--main) > div:first-child {
  padding-top: calc(var(--spacer) * 3);
}
section:not(.header--main) > div:last-child {
  padding-bottom: calc(var(--spacer) * 3);
}
section:not(.header--main) > div.flexibles:not(:last-child) {
  margin-bottom: calc(var(--spacer) * 2);
}
.header__main .nav {
  position: fixed;
  z-index: 10;
  transition: transform 250ms ease-out;
}
.header__main .nav__main {
  display: none;
}
@media (min-width: 768px) {
  .header__main .nav__main {
    display: flex;
  }
}
@media (min-width: 768px) {
  .header__main .nav__main .hexagones {
    display: none;
  }
}
@media (max-width: calc(768px - 1px)) {
  .header__main .nav__main.active {
    display: flex;
    background-color: var(--c-bg);
    position: fixed;
    inset: 0;
    z-index: 10;
    width: 100%;
  }
  .header__main .nav__main.active::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    z-index: 1;
    background-color: hsla(0, 0%, 100%, 0);
    pointer-events: none;
    opacity: 0.2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    filter: grayscale(100%);
  }
  .header__main .nav__main.active .menu-primary-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(var(--spacer) * 2);
    position: relative;
    z-index: 4;
  }
  .header__main .nav__main.active .nav--menu {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
  }
  .header__main .nav__main.active .nav--menu li {
    padding: 0;
  }
  .header__main .nav__main.active .nav--menu a {
    text-transform: uppercase;
    color: var(--c-primary-dark);
    font-size: 1.2rem;
  }
}
.header__main .row--full {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.header__main .nav__top .nav--menu > li,
.header__main .nav__main .nav--menu > li {
  transition: all 200ms ease-out;
}
.header__main .branding {
  transition: transform 300ms ease-out;
}
.header__main .nav {
  transition: all 200ms ease-out;
}
.header__main .nav::after, .header__main .nav::before {
  transition: all 200ms ease-out;
}
.header__main .nav {
  --menu-bg: var(--c-primary-dark);
  --h-nav: 200px;
  background-color: transparent;
  padding-left: 2rem;
}
.header__main .nav::before {
  position: absolute;
  left: calc(var(--h-nav) * -1);
  content: "";
  width: 0;
  bottom: 0;
  height: 0;
  border-style: solid;
  border-width: 0 var(--h-nav) var(--h-nav) 0;
  border-color: transparent var(--menu-bg) transparent transparent;
  opacity: 0;
}
.header__main .nav::after {
  position: absolute;
  right: calc(var(--p-outside) * -2);
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--menu-bg);
  opacity: 0;
}
.header__main.menu--bg .nav {
  background-color: var(--menu-bg);
}
.header__main.menu--bg .nav::before {
  opacity: 1;
}
.header__main.menu--bg .nav::after {
  opacity: 1;
}
.header__main.menu--small .nav__top .nav--menu > li,
.header__main.menu--small .nav__main .nav--menu > li {
  padding: calc(var(--spacer) * 1) 0;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .header__main.menu--fix .nav {
    transform: translateY(-110%);
  }
}
.header__main .branding {
  position: absolute;
  top: 0;
  margin: 0 auto;
  z-index: 18;
  filter: drop-shadow(1px 1px 0 rgba(var(--rgb-primary), 0.2));
  width: var(--branding-width);
  height: var(--branding-height);
}
.header__main .branding::before {
  position: absolute;
  content: "";
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
          clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  background-color: var(--c-primary);
  width: 110%;
  height: 110%;
  display: block;
  z-index: 2;
  background-color: var(--c-bg);
  width: 220%;
  height: 220%;
  top: -65%;
  left: -110%;
}
.header__main .branding a {
  position: relative;
  z-index: 3;
}
.pageheader {
  height: var(--h-window, 100vh);
  min-height: -webkit-fill-available;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .pageheader {
    height: 780px;
    min-height: 780px;
  }
}
.pageheader--404 {
  min-height: 100vh;
}
.pageheader--small {
  height: 780px;
  min-height: 780px;
}
@media (min-width: 768px) {
  .pageheader--small {
    height: 640px;
    min-height: 640px;
  }
}
.pageheader--small .pageheader--bg {
  animation-play-state: running;
}
.pageheader--search {
  height: 480px;
  min-height: 480px;
}
.pageheader__content {
  position: absolute;
  z-index: 5;
  width: 100%;
  bottom: var(--small-spacer);
  margin-bottom: var(--small-spacer);
}
@media (min-width: 768px) {
  .pageheader__content {
    position: absolute;
    top: inherit;
    max-width: 90%;
    margin-bottom: 0;
    bottom: 2rem;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .pageheader__content {
    max-width: 65%;
  }
}
.pageheader__content--frontpage {
  padding-left: calc((100vw - var(--w-max)) / 2 + (var(--spacer)));
}
.pageheader__content h1,
.pageheader__content .content {
  color: var(--c-bg);
}
.pageheader__content h1 {
  font-weight: var(--f-medium);
  font-size: 3.275rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  line-height: 0.8em;
  text-orientation: mixed;
  transform: rotate(270deg);
  transform-origin: var(--small-spacer) center;
}
@media (min-width: 768px) {
  .pageheader__content h1 {
    margin-left: 1.8rem;
    font-size: 4.875rem;
    writing-mode: inherit;
    transform: rotate(0deg);
  }
}
@media (min-width: 1540px) {
  .pageheader__content h1 {
    font-size: 6.875rem;
  }
}
.pageheader__content .content {
  font-weight: var(--f-light);
  font-size: 1.2em;
  padding: calc(var(--spacer) / 3);
}
@media (min-width: 768px) {
  .pageheader__content .content {
    font-size: 1.563em;
  }
}
.pageheader__vertical {
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  height: -moz-fit-content;
  height: fit-content;
  min-height: 3.9rem;
  width: 100vh;
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 20vh;
  transform-origin: left top;
  background-color: var(--c-bg);
}
@media (min-width: 768px) {
  .pageheader__vertical {
    transform-origin: left center;
    justify-content: flex-start;
    background-color: transparent;
    width: 90vh;
    padding-right: 40vh;
  }
}
@media (min-width: 1024px) {
  .pageheader__vertical {
    padding-right: 30vh;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .pageheader__vertical {
    padding-right: calc(var(--branding-width) * 4);
  }
}
@media (min-width: 1540px) {
  .pageheader__vertical {
    padding-right: 35vh;
  }
}
.pageheader--titles {
  height: var(--h-window);
  position: relative;
  z-index: 7;
  width: -moz-fit-content;
  width: fit-content;
}
@media (min-width: 768px) {
  .pageheader--titles {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
    overflow: hidden;
    min-width: calc(var(--branding-width));
    height: 100%;
  }
}
@media (min-width: 1540px) {
  .pageheader--titles {
    min-width: var(--first-col);
  }
}
.pageheader--titles .titles-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 100%;
}
@media (min-width: 768px) {
  .pageheader--titles .titles-container {
    width: 60vh;
    bottom: 6rem;
    left: 47%;
  }
}
@media (min-width: 1540px) {
  .pageheader--titles .titles-container {
    left: max(47%, var(--w-max) * 0.1 + var(--max-outside));
  }
}
.pageheader--titles .icon__cat {
  animation-name: fade, top;
  animation-delay: 400ms;
  animation-duration: 250ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
  transform: rotate(90deg);
  width: 2.3rem;
  height: 2.3rem;
}
@media (min-width: 768px) {
  .pageheader--titles .icon__cat {
    min-width: 4rem;
    min-height: 4rem;
    margin-top: 0;
  }
}
.pageheader--titles .icon__cat + .group {
  margin-left: 1.5rem;
}
@media (min-width: 768px) {
  .pageheader--titles .icon__cat + .group {
    margin-left: 3rem;
  }
}
@media (min-width: 1024px) {
  .pageheader--titles .icon__cat + .group {
    margin-left: 1.5rem;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .pageheader--titles .icon__cat + .group {
    margin-left: 3rem;
  }
}
.pageheader--titles .group {
  width: clamp(50%, 70rem, 100%);
}
.pageheader--title {
  font-weight: var(--f-medium);
  animation-name: fade, right;
  animation-delay: 0;
  animation-duration: 700ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
  display: block;
  width: 100%;
  color: var(--c-primary);
  text-transform: uppercase;
  line-height: 100%;
  font-size: 1.1em;
}
@media (min-width: 768px) {
  .pageheader--title {
    font-size: 2.2em;
    line-height: 1;
  }
}
@media (min-width: 1024px) {
  .pageheader--title {
    font-size: 2em;
  }
}
@media (min-width: 1540px) {
  .pageheader--title {
    font-size: 2.78em;
  }
}
.pageheader--pretitle {
  font-weight: var(--f-bold);
  font-size: 1.12em;
  display: block;
  text-transform: uppercase;
  animation-name: fade, right;
  animation-delay: 300ms;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.pageheader__bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.pageheader--hero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 6;
  pointer-events: none;
}
.pageheader--hero::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgb(14, 21, 51);
  background: linear-gradient(180deg, rgb(14, 21, 51) 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
  opacity: 0.8;
  z-index: 3;
  height: 30vh;
  pointer-events: none;
  transition: all 200ms ease-out;
}
@media (min-width: 768px) {
  .pageheader--hero {
    width: 100%;
    position: relative;
  }
}
.pageheader--hero img {
  transform: translateY(calc(-0.5 * var(--scrollY)));
  position: relative;
  z-index: 5;
  max-width: 90%;
  height: auto;
  width: auto;
  max-height: 52vh;
  margin: 0 auto;
  object-fit: contain;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .pageheader--hero img {
    transform: translateY(calc(-1 * var(--scrollY)));
    width: auto;
    max-height: 60%;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .pageheader--hero img {
    margin: 0 auto 0 20%;
  }
}
@media (min-width: 1540px) {
  .pageheader--hero img {
    margin: 0 auto 0 0;
  }
}
.pageheader--bg {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--c-primary);
  animation-name: fade;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: paused;
}
.pageheader--bg.appear {
  animation-play-state: running;
}
.pageheader--bg.run {
  animation-play-state: running;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .pageheader--bg {
    background-position: 50% center;
    background-attachment: fixed;
  }
  .pageheader--bg--mobile {
    display: none;
  }
}
@media (min-width: 1540px) {
  .pageheader--bg {
    background-position: 20% center;
  }
}
@media (min-width: 1920px) {
  .pageheader--bg {
    background-position: 50% center;
  }
}
@media (min-width: 768px) {
  body.show--modal .header__main .nav {
    display: none;
  }
}
.nav {
  position: fixed;
  display: flex;
  z-index: 10;
  gap: calc(var(--spacer) * 2);
  right: var(--spacer);
}
@media (min-width: 1540px) {
  .nav {
    gap: calc(var(--spacer) * 4);
    right: var(--branding-height);
    margin-left: var(--branding-height);
  }
}
.nav__top {
  display: flex;
  align-items: center;
  gap: calc(var(--spacer) / 1);
}
.nav__top .btn__search--close {
  width: 20px;
  height: 20px;
}
.nav__top .btn__label {
  display: none;
}
@media (min-width: 768px) {
  .nav__top .burger {
    display: none;
  }
}
.nav__top .nav--menu, .nav__main .nav--menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: calc(var(--spacer) * 3);
}
.nav__top .nav--menu .sub-menu, .nav__main .nav--menu .sub-menu {
  margin: 0;
  padding: 0;
}
.nav__top .nav--menu li, .nav__main .nav--menu li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav__top .nav--menu > li, .nav__main .nav--menu > li {
  padding: calc(var(--spacer) * 2) 0;
}
.nav__top .nav--menu a, .nav__main .nav--menu a {
  color: var(--c-bg);
  font-size: 1em;
  font-weight: var(--f-medium);
  text-transform: uppercase;
}
.footer__main {
  background-color: #0e1533;
  color: var(--c-bg);
  position: relative;
  z-index: 2;
}
.footer__main .branding {
  width: 14rem;
  margin: 0 auto;
  padding: var(--small-spacer);
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .footer__main .branding {
    padding: var(--spacer);
  }
  .footer__main .branding .brand div {
    width: 100%;
  }
  .footer__main .branding .brand svg {
    width: 10rem;
  }
}
.footer__main .branding svg path,
.footer__main .branding svg polygon {
  fill: var(--c-bg) !important;
}
.footer__content .row-full {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
@media (min-width: 768px) {
  .footer__content .row-full {
    display: grid;
    grid-template-columns: 20% 80%;
  }
}
.footer__content .row-full .nav__footer {
  width: 100%;
  padding: calc(var(--spacer) * 2);
}
@media (min-width: 768px) {
  .footer__content .row-full .nav__footer {
    padding: var(--spacer) 0;
    margin-bottom: var(--spacer);
  }
}
.footer__content .row-full .nav__footer .nav--footer {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-content: space-between;
  font-size: 0.9rem;
}
.footer__content .row-full .nav__footer .nav--footer .sub-menu {
  margin: 0;
  padding: 0;
}
.footer__content .row-full .nav__footer .nav--footer li {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .footer__content .row-full .nav__footer .nav--footer {
    grid-auto-flow: column;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
  }
  .footer__content .row-full .nav__footer .nav--footer > li {
    grid-row: 1/3;
  }
}
.footer__content .row-full .nav__footer div > .nav--footer > li:not(.link__newsletter) > a,
.footer__content .row-full .nav__footer div > .nav--footer > li.has__children > a {
  font-weight: var(--f-bold);
  font-size: 1.14em;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .footer__content .row-full .nav__footer div > .nav--footer > li:not(.link__newsletter) > a,
  .footer__content .row-full .nav__footer div > .nav--footer > li.has__children > a {
    margin-bottom: 1em;
  }
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin,
.footer__content .row-full .nav__footer div > .nav--footer .link__newsletter {
  position: relative;
  float: left;
  padding-top: 2rem;
  padding-right: 2rem;
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin a,
.footer__content .row-full .nav__footer div > .nav--footer .link__newsletter a {
  position: relative;
  display: block;
  width: 45px;
  height: 50px;
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin a::after,
.footer__content .row-full .nav__footer div > .nav--footer .link__newsletter a::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  transition: transform 200ms ease-in;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin a span,
.footer__content .row-full .nav__footer div > .nav--footer .link__newsletter a span {
  display: none;
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin a:hover span,
.footer__content .row-full .nav__footer div > .nav--footer .link__newsletter a:hover span {
  display: block;
  position: absolute;
  font-size: 0.75rem;
  text-transform: uppercase;
  text-align: center;
  left: -70%;
  top: 120%;
  right: -70%;
  color: white;
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin a:hover::after,
.footer__content .row-full .nav__footer div > .nav--footer .link__newsletter a:hover::after {
  transform: scale(1.2);
}
.footer__content .row-full .nav__footer div > .nav--footer .link__linkedin a::after {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
.flexibles--gallery {
  --c-bg: var(--c-grey-light);
  transform: translateY(calc(var(--poly-spacer) * -0.5));
  padding-top: calc(var(--poly-spacer) * 1);
  padding-bottom: calc(var(--poly-spacer) * 1);
  z-index: 2;
  animation-name: polyfull;
  animation-delay: var(--delay, 0);
  animation-duration: var(--d-poly);
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
  position: relative;
  position: relative;
  margin: 3rem 0;
  background-color: var(--c-bg);
}
@media (min-width: 768px) {
  .flexibles--gallery {
    padding-top: calc(var(--poly-spacer) * 2.4);
    transform: translateY(calc(var(--poly-spacer) * -1));
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .flexibles--gallery {
    padding-top: calc(var(--poly-spacer) * 2);
    padding-bottom: calc(var(--poly-spacer) * 1);
    transform: translateY(calc(var(--poly-spacer) * -1));
  }
}
.flexibles--gallery::before {
  content: "";
  position: absolute;
  background-color: var(--c-bg);
  height: 6rem;
  top: calc(-6rem + 2px);
  width: 100%;
  display: block;
  z-index: 1;
  pointer-events: none;
}
.flexibles--gallery::after {
  content: "";
  position: absolute;
  background-color: var(--c-bg);
  height: 10rem;
  bottom: -9rem;
  width: 100%;
  display: block;
  z-index: 1;
  pointer-events: none;
}
@media (min-width: 768px) {
  .flexibles--gallery {
    margin: 2rem 0;
  }
}
@media (min-width: 1024px) {
  .flexibles--gallery {
    margin: 5rem 0;
  }
}
.flexibles--gallery .gallery__list {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .flexibles--gallery .gallery__list {
    width: 90%;
  }
}
.flexibles--gallery img {
  width: 100%;
}
.gallery__container {
  display: flex;
  align-items: center;
  gap: calc(var(--spacer) * 1);
}
@media (min-width: 768px) {
  .gallery__container {
    gap: calc(var(--spacer) * 2);
  }
}
.gallery__container .splide__track .splide__slide {
  padding: calc(var(--spacer) / 2) calc(var(--spacer) / 4);
}
.gallery__container .splide__track .splide__slide .open-modal {
  position: absolute;
  inset: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
}
.gallery__container .splide__slide__row {
  align-items: center;
}
.gallery__container .splide__arrows [role=button] {
  cursor: pointer;
  transition: transform 200ms ease-out;
}
.gallery__container .splide__arrows [role=button]:hover {
  transform: scale(0.9);
}
.gallery__container .splide__arrows .splide__arrow--next svg {
  transform: rotate(180deg);
}
.gallery__slide--modal figure {
  height: 80vh;
  width: 100%;
}
.gallery__slide--modal img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.flexibles .row--grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(var(--spacer) * 4);
}
@media (min-width: 768px) {
  .flexibles .row--grid {
    grid-template-columns: repeat(var(--col, 1), 1fr);
    gap: calc(var(--spacer) * 2);
  }
}
.flexibles .row--grid .col--image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexibles .row--grid .col--image .container {
  width: 80%;
}
.flexibles .col--list {
  display: flex;
  flex-direction: column;
  gap: var(--spacer);
  padding: calc(var(--spacer) * 1);
}
.flexibles .col--list .title {
  text-transform: uppercase;
  color: var(--c-primary-dark);
  font-size: 2.2rem;
}
.flexibles .col--list .pretitle {
  font-size: 0.8em;
  color: var(--c-primary-dark);
  font-weight: var(--f-regular);
  padding-bottom: 1em;
  text-transform: uppercase;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: table;
}
.flexibles .col--list .pretitle::after {
  content: "";
  position: absolute;
  display: block;
  top: 0.35rem;
  left: auto;
  right: -110%;
  bottom: 0;
  z-index: 1;
  height: 1px;
  width: 100%;
  background: var(--c-primary-dark);
}
.flexibles .col--list .request-brochure {
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(var(--spacer) * 2);
}
@media (min-width: 768px) {
  .flexibles .col--list .request-brochure {
    margin-left: 0;
    margin-right: 0;
  }
}
.flexibles .col--list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  list-style: none;
}
.flexibles .col--list ul .sub-menu {
  margin: 0;
  padding: 0;
}
.flexibles .col--list ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.flexibles .col--list ul li {
  --size: 1.6em;
  position: relative;
}
.flexibles .col--list ul li::before {
  content: "";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 50%;
  width: var(--size);
  height: var(--size);
  transform: translateY(-50%);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  background-color: transparent;
  background-size: 98%;
  background-repeat: no-repeat;
  background-position-x: 0.3rem;
  background-position-y: 0.2rem;
}
.flexibles .col--list ul li {
  border-bottom: 1px solid rgba(var(--rgb-primary), 0.1);
  padding: 1.5rem calc(var(--size) + var(--spacer) * 0.5);
  margin: 0;
  color: var(--c-primary-dark);
  font-weight: var(--f-bold);
  font-size: 1.2em;
  text-transform: uppercase;
}
/* New single content */
.news__list .row {
  display: grid;
  gap: var(--spacer);
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .news__list .row {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .news__list .row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.news__list .row .news-item {
  background-color: var(--c-primary-dark);
  aspect-ratio: 4/6;
}
@media (min-width: 768px) {
  .news__list .row .news-item {
    aspect-ratio: 5/6;
  }
}
@media (min-width: 1024px) {
  .news__list .row .news-item {
    aspect-ratio: 6/4;
  }
}
@media (min-width: 1540px) {
  .news__list .row .news-item {
    aspect-ratio: 6/4;
  }
}
.news__list .row .news-item .bg {
  height: 100%;
}
.news__list .row .news-item .bg .figure {
  height: 100%;
  width: 100%;
}
.news__list .row .news-item .bg .figure__container {
  height: 100%;
  width: 100%;
}
.news__list .row .news-item .bg .figure__container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.news-list__container .row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  gap: calc(var(--spacer) * 2);
}
@media (min-width: 768px) {
  .news-list__container .row {
    gap: calc(var(--spacer) * 1);
    grid-template-columns: 1fr 1fr;
  }
  .news-list__container .row .col:last-child {
    order: -1;
    grid-column: 1/-1;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .news-list__container .row {
    display: grid;
    gap: calc(var(--spacer) * 1);
    grid-template-columns: 1fr 1fr 1fr;
  }
  .news-list__container .row .col:last-child {
    order: initial;
    grid-column: auto;
  }
}
.news-list__container .row .splide__list {
  display: flex;
  gap: var(--spacer);
}
.news-list__container .row aside {
  display: flex;
  flex-direction: column;
  gap: var(--spacer);
  padding: calc(var(--spacer) * 2);
}
.news-list__container .row aside h2 {
  text-transform: uppercase;
  color: var(--c-primary-dark);
}
.news-list__container .row .splide__pagination {
  flex-direction: row;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  right: auto;
}
.news-list__container .row .splide__pagination .splide__pagination__page {
  border-radius: 0px;
  width: 6rem;
  height: 3px;
  background-color: var(--c-bg);
}
.news-list__container .row .splide__pagination .splide__pagination__page.is-active {
  background-color: var(--c-primary-dark);
}
.news__container {
  font-size: 1.25rem;
  padding: var(--spacer) var(--small-spacer);
}
.news__container header {
  color: var(--c-primary);
  text-transform: uppercase;
}
.news__container header h1 {
  font-size: 3rem;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .news__container header h1 {
    font-size: 4.5rem;
    line-height: 1.1;
  }
}
.news__container header .news__titles--subtitle {
  font-weight: var(--f-light);
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--c-grey);
}
.news__container .entry-content {
  padding-top: var(--spacer);
  display: grid;
  grid-template-columns: 1fr;
}
@media (max-width: calc(1024px - 1px)) {
  .news__container .entry-content .content {
    grid-row: 2;
  }
  .news__container .entry-content .news__aside {
    grid-row: 1;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .news__container .entry-content {
    grid-template-columns: 65% 30%;
    gap: 5%;
  }
}
.news__container .entry-content .video-responsive video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
}
.news__container .entry-content .wp-video-shortcode {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/9;
  margin-bottom: var(--spacer);
}
.news__container .entry-content .read-more__item h3 {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-left: calc(var(--spacer) / 2 - 4px);
}
.news__container .entry-content .read-more__item h3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 4px;
  height: 100%;
  background-color: var(--c-secondary);
}
.news__container .entry-content .read-more__item .content {
  margin-left: calc(var(--spacer) / 2);
}
.news__container footer {
  padding: calc(var(--spacer) * 2) 0 0;
}
.news__container footer .footer-nav__container {
  display: flex;
  justify-content: center;
}
.news__aside {
  width: 100%;
}
.news__aside .video-responsive,
.news__aside .image__container {
  position: sticky;
  top: 0;
}
@media (max-width: calc(1024px - 1px)) {
  .news__aside .video-responsive,
  .news__aside .image__container {
    display: flex;
  }
  .news__aside .video-responsive img,
  .news__aside .image__container img {
    max-height: 40vh;
    width: auto;
    margin: 0 auto;
  }
}
.news__aside .video-responsive {
  top: 5rem;
  margin-bottom: var(--spacer);
}
.news__date span {
  font-size: 1.5em;
  margin-bottom: 1rem;
}
.news__date::before {
  content: none;
}
.home .header--main::after {
  content: "";
  position: absolute;
  display: block;
  top: var(--h-heroes);
  left: 0;
  right: 0;
  bottom: calc(var(--h-heroes) * -1);
  z-index: 1;
  height: var(--h-heroes);
  background: var(--c-bg);
}
.main__content {
  --bg-color: var(--bg-color);
  --text-color: var(--c-primary-dark);
  background-color: var(--c-bg);
  color: var(--c-text);
  font-size: 1rem;
}
@media (min-width: 380px) {
  .main__content {
    font-size: 1.2rem;
  }
}
@media (min-width: 768px) {
  .main__content {
    font-size: 1.04rem;
  }
}
@media (min-width: 1540px) {
  .main__content {
    font-size: 1.36rem;
  }
}
@media (min-width: 1920px) {
  .main__content {
    font-size: 1.56rem;
  }
}
.main__content .container .row .content h1,
.main__content .container .row .content h3,
.main__content .container .row .content .h1 {
  font-weight: var(--f-light);
  font-size: 2.65em;
  margin-bottom: 0.5em;
}
.main__content .container .row .content h1 strong,
.main__content .container .row .content h3 strong,
.main__content .container .row .content .h1 strong {
  font-family: var(--f-medium);
}
@media (min-width: 1920px) {
  .main__content .container .row .content h1,
  .main__content .container .row .content h3,
  .main__content .container .row .content .h1 {
    font-size: 3.2em;
    margin-bottom: 0.3em;
  }
}
.main__content .container .row .content h2 {
  font-weight: var(--f-light);
  font-size: 1.52em;
  margin-bottom: 1.5rem;
}
.main__content .container .row .content ul {
  margin-left: calc(var(--spacer) / 4);
  list-style-type: none;
}
.main__content .container .row .content ul li {
  position: relative;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .main__content .container .row .content ul li {
    padding-bottom: 2rem;
  }
}
.main__content .container .row .content ul li::before {
  content: "";
  display: block;
  min-width: 30px;
  width: 30px;
  height: 30px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  background-repeat: no-repeat;
  position: absolute;
  left: -2em;
  top: calc(50% - 27px);
}
.main__content .container__desc {
  animation-name: top, fade;
  animation-delay: 0.6s;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: paused;
  margin-bottom: 1.5rem;
}
.main__content .container__desc.appear {
  animation-play-state: running;
}
.main__content--news .container, .main__content--agenda .container, .main__content--category .container {
  padding-top: var(--spacer);
  padding-bottom: var(--spacer);
}
.main__content--news .container .row .col, .main__content--agenda .container .row .col, .main__content--category .container .row .col {
  padding: 0 var(--small-spacer);
}
@media (min-width: 768px) {
  .main__content--news .container .row .col, .main__content--agenda .container .row .col, .main__content--category .container .row .col {
    padding: 0 var(--spacer);
  }
}
@media (min-width: 1540px) {
  .main__content--news .container .row .col, .main__content--agenda .container .row .col, .main__content--category .container .row .col {
    max-width: 100%;
  }
}
.main__content--news .container .row .col h1, .main__content--agenda .container .row .col h1, .main__content--category .container .row .col h1 {
  font-size: 2em;
  padding-bottom: 0.5em;
  font-family: var(--f-normal);
}
.main__content--news .container .row .col .content, .main__content--agenda .container .row .col .content, .main__content--category .container .row .col .content {
  line-height: 1.5em;
}
.main__content--news .container .row .col .content p, .main__content--agenda .container .row .col .content p, .main__content--category .container .row .col .content p {
  padding-bottom: 1.4em;
}
.main__content--news .container .row .col .content ul li, .main__content--agenda .container .row .col .content ul li, .main__content--category .container .row .col .content ul li {
  list-style-type: none;
}
.main__content--news .container .row .col .content ul li::before, .main__content--agenda .container .row .col .content ul li::before, .main__content--category .container .row .col .content ul li::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
  background-repeat: no-repeat;
}
.main__content .image__container {
  --spot-image-width: 100%;
}
@media (min-width: 768px) {
  .main__content .image__container {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .main__content .image__container {
    width: var(--spot-image-width);
    height: 100%;
  }
}
.main__content .image__line {
  width: 100%;
  height: 100%;
  min-height: 70%;
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .main__content .image__line {
    width: calc(var(--spot-image-width) * 0.95);
  }
}
@media (min-width: 768px) {
  .main__content--generic .container .row {
    grid-template-columns: 50% 50%;
  }
}
.main__content--generic .container .row .col:first-child {
  padding: 0 var(--small-spacer);
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .main__content--generic .container .row .col:first-child {
    padding: 0 calc(var(--spacer));
  }
}
.main__content--generic .container .row .content h1 {
  color: var(--c-primary);
}
.main__content--generic .container .row .content h2 {
  color: var(--c-grey);
}
.main__content--generic .container .row .image__container {
  --spot-image-width: 50vw;
}
@media (max-width: calc(768px - 1px)) {
  .main__content--generic .container .row .image__container {
    margin-top: var(--small-spacer);
  }
}
@media (min-width: 768px) {
  .main__content--generic .container .row .image__container {
    width: var(--spot-image-width);
    height: var(--spot-image-width);
    margin: 0 0 0 auto;
  }
}
@media (min-width: 1024px) {
  .main__content--generic .container .row .image__container {
    --spot-image-width: 100%;
  }
}
#fourzerofour-content {
  background-color: var(--c-bg);
  position: absolute;
  z-index: 20;
  left: 20%;
  top: 50%;
  padding: calc(var(--spacer) * 2);
}
.blog .flexibles__content h1 {
  font-size: 2.8rem;
  color: var(--c-primary-dark);
  font-weight: var(--f-light);
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .blog .flexibles__content {
    max-width: 60%;
  }
}
.blog .footer-nav__container {
  padding: calc(var(--spacer) * 2) 0;
  display: flex;
  justify-content: center;
}
.blog .footer-nav__container nav {
  display: flex;
  gap: var(--spacer);
}
.blog .footer-nav__container nav span {
  font-weight: var(--f-bold);
}
.search h1 {
  font-size: 2.8rem;
  color: var(--c-primary-dark);
  font-weight: var(--f-light);
  text-transform: uppercase;
}
.search .message,
.search .search__item {
  border-bottom: 1px solid var(--c-grey-light);
  padding: var(--spacer) 0;
}
.search .result__list form {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 6;
  width: auto;
  max-width: 70vw;
  margin: var(--spacer) 0;
}
.search .result__list form label {
  padding: 0;
}
.search .result__list form label span {
  display: none;
}
.search .result__list form input {
  animation-name: fade, left;
  animation-delay: 400ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.search .result__list form [type=search] {
  border: 1px solid var(--c-primary-dark);
}
.search .result__list form [type=search]::-webkit-search-cancel-button {
  display: none;
}
.search .result__list form [type=submit] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  min-width: auto;
  padding: 0.45rem 1.4rem;
  background-color: var(--c-primary-dark);
}
.search .result__list form input[type=search] {
  transition: none;
  animation: none;
  padding: 7px;
  width: 60vw;
}
@media (min-width: 768px) {
  .search .result__list form input[type=search] {
    width: 30vw;
  }
}
.page-template-template-maintenance .page__content {
  height: 90vh;
}
.page-template-template-maintenance .page__content .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  justify-content: center;
}
.page-template-template-maintenance .page__content .col h1 {
  display: flex;
  font-size: 2rem;
}
.CookieDeclaration {
  display: none;
}
body {
  /* Branding on the banner */
  /* Branding on the Privacy trigger */
}
body a#CybotCookiebotDialogPoweredbyCybot,
body div#CybotCookiebotDialogPoweredByText {
  display: none;
}
body #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none;
}
body #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo,
body a#CybotCookiebotDialogPoweredbyCybot,
body div#CybotCookiebotDialogPoweredByText {
  display: none;
}
/** Libraries */
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
          user-select: none;
}
.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}
.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}
.splide--rtl {
  direction: rtl;
}
.splide__track--ttb > .splide__list {
  display: block;
}
.splide__container {
  box-sizing: border-box;
  position: relative;
}
.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}
.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}
.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}
.splide:not(.is-overflow) .splide__pagination {
  display: none;
}
.splide__progress__bar {
  width: 0;
}
.splide {
  position: relative;
  visibility: hidden;
}
.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}
.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}
.splide__slide img {
  vertical-align: bottom;
}
.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}
.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.splide__toggle__pause {
  display: none;
}
.splide__toggle.is-active .splide__toggle__play {
  display: none;
}
.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}
.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
/** Components */
.branding {
  display: flex;
  align-items: center;
}
.branding .brand {
  display: flex;
  text-indent: -9999px;
  white-space: nowrap;
  justify-content: center;
  margin: calc(var(--spacer) / 2) 0;
}
.branding .brand div {
  width: 65%;
}
.branding .brand img,
.branding .brand svg {
  height: auto;
  width: 100%;
}
.branding__footer {
  position: relative;
}
.branding__footer .brand img,
.branding__footer .brand svg {
  width: 8rem;
  margin: 0 auto;
  height: auto;
}
.btn {
  display: inline-flex;
  align-items: center;
  width: auto;
  font-family: var(--f-title);
  color: var(--c-btn-text);
  padding: 0.5em 1.2em;
  -webkit-text-decoration: none;
  text-decoration: none;
  gap: 0.5em;
  position: relative;
  z-index: 2;
  overflow: hidden;
  border-radius: 1.2em;
  background-clip: padding-box;
  box-shadow: 0 0 1px 1px var(--c-btn-bg), inset 0 0 2px 2px var(--c-btn-bg);
}
.btn::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  transition: transform 250ms var(--bezier);
  inset: -100% -20%;
  transform: translateY(25%);
  background-clip: padding-box;
  background-image: linear-gradient(0deg, var(--c-btn-bg-hover) 0%, var(--c-btn-bg-hover) 50%, var(--c-btn-bg) 51%, var(--c-btn-bg) 100%);
  background-position: center -50%;
  z-index: -1;
}
.btn span {
  display: inline;
  width: -moz-fit-content;
  width: fit-content;
}
.btn--primary {
  --c-btn-text: var(--c-bg);
  --c-btn-bg: var(--c-primary);
  --c-btn-text-hover: var(--c-primary);
  --c-btn-bg-hover: var(--c-bg);
}
.btn--secondary {
  --c-btn-text: var(--c-bg);
  --c-btn-bg: var(--c-secondary);
  --c-btn-text-hover: var(--c-secondary);
  --c-btn-bg-hover: var(--c-bg);
}
.btn--icon svg {
  aspect-ratio: 1/1;
  width: 1em;
}
.btn--icon svg path {
  fill: var(--c-bg);
}
.btn:focus, .btn:hover {
  color: var(--c-btn-text-hover);
  background-color: var(--c-btn-bg-hover);
}
.btn:focus::before, .btn:hover::before {
  transform: translateY(-25%);
}
.btn:focus svg path, .btn:hover svg path {
  fill: var(--c-btn-text-hover);
}
.btn__faq {
  font-weight: var(--f-medium);
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  background-color: var(--c-grey-light);
  min-width: 100%;
}
@media (min-width: 768px) {
  .btn__faq {
    min-width: 65%;
  }
}
@media (min-width: 1024px) {
  .btn__faq {
    min-width: 40%;
  }
}
.btn__faq::before {
  content: "";
  position: absolute;
  display: block;
  top: 2rem;
  left: -2rem;
  right: calc(100% - 2rem);
  bottom: auto;
  z-index: 1;
  height: 2px;
  width: 4rem;
  background-color: var(--c-primary);
  transition: all 200ms ease-in;
}
.btn__faq::after {
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: calc(100% - 2rem);
  right: -2rem;
  bottom: 2rem;
  z-index: 1;
  height: 2px;
  width: 4rem;
  transition: all 200ms ease-in;
  background-color: var(--c-primary);
}
.btn__faq button,
.btn__faq a {
  color: var(--c-primary);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  margin: 0 auto;
  font-size: 2.2em;
  padding: var(--small-spacer) var(--small-spacer);
  left: calc(var(--spacer) * 1.5);
}
@media (min-width: 768px) {
  .btn__faq button,
  .btn__faq a {
    padding: var(--small-spacer) 1rem;
  }
}
.btn__faq button span,
.btn__faq a span {
  transition: all 200ms ease-in-out;
  position: relative;
  left: calc(var(--spacer) * -2.5);
}
.btn__faq button::before,
.btn__faq a::before {
  content: "";
  display: inline-block;
  width: 80px;
  height: 80px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 200ms ease-in-out;
  left: 40px;
}
@media (hover: hover) {
  .btn__faq:hover::before {
    right: -2rem;
    left: calc(100% - 2rem);
  }
  .btn__faq:hover::after {
    left: -2rem;
    right: calc(100% - 2rem);
  }
  .btn__faq:hover a,
  .btn__faq:hover button {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
  .btn__faq:hover a span,
  .btn__faq:hover button span {
    transform: translateX(1rem);
  }
  .btn__faq:hover a::before,
  .btn__faq:hover button::before {
    transform: scale(1.2) translateX(-1.5rem);
  }
}
.burger {
  --c-burger-primary: var(--c-primary-dark);
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  z-index: 10;
  min-width: 2.5rem;
  width: auto;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  background-color: transparent;
  transition: background-color 250ms ease-in-out;
}
.burger::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: -1;
  background-color: var(--c-bg);
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .burger:hover .burger__line:nth-child(3), .burger:hover .burger__line:nth-child(4) {
    width: var(--line-width);
  }
}
.burger__line {
  --line-width: 100%;
  display: block;
  position: relative;
  width: var(--line-width);
  height: 3px;
  background-color: var(--c-bg);
  transition: transform 200ms ease-in, width 150ms ease-in;
  transform-origin: right;
}
.burger__line:nth-child(3), .burger__line:nth-child(4) {
  width: 60%;
}
.burger__line:nth-child(1) {
  transition-delay: 100ms, 50ms;
}
.burger__line:nth-child(2) {
  transition-delay: 200ms, 100ms;
}
.burger__line:nth-child(3) {
  transition-delay: 300ms, 150ms;
}
.burger__line:nth-child(4) {
  transition-delay: 400ms, 200ms;
}
.burger__close {
  --close-size: 55%;
  --close-thick: 3px;
  display: block;
  width: var(--close-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%) translateY(-50%);
  transform-origin: top left;
  aspect-ratio: 1/1;
}
.burger__close::before, .burger__close::after {
  content: "";
  display: block;
  background-color: var(--c-bg);
  position: absolute;
  transition: transform 0.2s ease-in-out, background-color 350ms var(--bezier);
}
.burger__close::before {
  width: var(--close-thick);
  height: 100%;
  top: 0;
  left: calc(50% - var(--close-thick) / 2);
  transition-delay: 0.15s;
  transform: scaleY(0);
  transform-origin: top;
}
.burger__close::after {
  width: 100%;
  height: var(--close-thick);
  top: calc(50% - var(--close-thick) / 2);
  left: 0;
  transition-delay: 0;
  transform: scaleX(0);
  transform-origin: right;
}
.active .burger {
  background-color: transparent;
}
.active .burger::after {
  opacity: 1;
  transition: opacity 350ms ease-in 300ms;
}
.active .burger__line {
  transform: scaleX(0);
}
.active .burger__line:nth-child(1) {
  transition-delay: 0s;
}
.active .burger__line:nth-child(2) {
  transition-delay: 0.15s;
}
.active .burger__line:nth-child(3) {
  transition-delay: 0.3s;
}
.active .burger__line:nth-child(4) {
  transition-delay: 0.45s;
}
.active .burger__close::before {
  transform: scaleY(1);
  transition-delay: 0.4s;
  background-color: var(--c-burger-primary);
}
.active .burger__close::after {
  transform: scaleX(1);
  transition-delay: 0.55s;
  background-color: var(--c-burger-primary);
}
.pll-parent-menu-item .sub-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  display: none;
}
.pll-parent-menu-item:hover .sub-menu {
  display: block;
}
.header-nav {
  --corner: 32px;
  font-size: 1.25rem;
  position: absolute;
  z-index: 6;
  width: calc(100% - var(--corner));
  min-height: calc(var(--corner) * 2);
  bottom: 0;
  background-color: var(--c-bg);
}
@media (min-width: 768px) {
  .header-nav {
    --corner: 48px;
  }
}
@media (min-width: 768px) {
  .header-nav {
    left: 0;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .header-nav {
    width: 80%;
  }
}
@media (min-width: 1540px) {
  .header-nav {
    width: auto;
    min-width: 50%;
  }
}
.header-nav::before {
  content: "";
  position: absolute;
  top: 0;
  right: calc(var(--corner) * -1);
  left: calc(100% - 0px);
  width: var(--corner);
  height: var(--corner);
  border-style: solid;
  border-width: var(--corner) 0 0 var(--corner);
  border-color: transparent transparent transparent var(--c-bg);
}
.header-nav::after {
  content: "";
  position: absolute;
  bottom: -1px;
  width: var(--corner);
  height: calc(var(--corner) + 1px);
  right: calc((var(--corner) - 0px) * -1);
  background: var(--c-bg);
}
.header-nav:not(.no-link) {
  background-color: var(--c-bg);
  border-bottom: 1px solid var(--c-grey);
}
.header-nav:not(.no-link)::after {
  border-bottom: 1px solid var(--c-grey);
}
.header-nav--container {
  display: grid;
  justify-content: space-around;
  height: calc(var(--corner) * 2);
  grid-template-columns: 3.8rem 1fr;
}
@media (min-width: 768px) {
  .header-nav--container {
    grid-template-columns: var(--branding-width) 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .header-nav--container {
    grid-template-columns: var(--branding-width) 1fr 1fr 1fr;
  }
}
@media (min-width: 1540px) {
  .header-nav--container {
    grid-template-columns: var(--first-col) repeat(3, auto);
    justify-content: left;
  }
}
.header-nav--container::before {
  content: "";
  display: block;
}
.header-nav .link__container {
  align-content: center;
  display: flex;
}
.header-nav .link__container:first-child {
  position: relative;
}
@media (min-width: 768px) {
  .header-nav .link__container:first-child:not(.end-link) {
    border-right: 1px solid var(--c-grey);
  }
}
.header-nav .link__container:first-child::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: auto;
  bottom: -50%;
  z-index: 1;
  width: 1px;
  text-align: left;
  background-color: var(--c-grey);
}
.header-nav .link__container:first-child::after {
  content: "";
  position: absolute;
  display: block;
  top: 150%;
  left: -2px;
  right: auto;
  bottom: -50%;
  z-index: 1;
  width: 5px;
  height: 5px;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}
.header-nav .link__container:not(:first-child) {
  display: none;
}
@media (min-width: 768px) {
  .header-nav .link__container:not(:first-child) {
    display: flex;
  }
}
.header-nav .link {
  --btn-link-color: var(--c-primary);
  width: 100%;
  text-transform: uppercase;
  color: var(--btn-link-color);
  margin: 0.5rem 2rem;
  font-weight: var(--f-bold);
}
.header-nav .link .arrow {
  --arrow-width: 1.5rem;
}
@media (min-width: 768px) {
  .header-nav .link {
    margin: 1.5rem 2rem;
  }
}
.header-nav .link--previous {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.header-nav .link--previous .arrow {
  margin-left: 0;
  margin-right: 0.5rem;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .header-nav .link--previous .arrow {
    margin-right: 1.2rem;
  }
}
.header-nav .link--next span {
  margin-right: 0.5rem;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .header-nav .link--next span {
    margin-right: 1.2rem;
  }
}
.header-nav .link--next .arrow {
  transform: rotate(180deg);
  margin-left: 0;
}
.header-nav .link--back {
  font-weight: var(--f-medium);
  display: flex;
  align-items: center;
  position: relative;
  font-size: 0.6em;
  /* Square with dots before back button */
  /* Horizontal line before back button */
}
.header-nav .link--back::before {
  content: "";
  position: absolute;
  display: block;
  top: calc(50% - 9px);
  left: -65px;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 125px;
  height: 18px;
  background-repeat: no-repeat;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_10___});
  background-size: contain;
  background-position: left center;
  transition: transform 200ms;
  transform-origin: left center;
}
.header-nav .link--back::after {
  content: "";
  position: absolute;
  display: block;
  top: calc(50% - 1px);
  left: -3.1rem;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_11___});
  width: 35px;
  height: 1px;
  background-color: var(--c-primary);
  background-size: contain;
  background-position: left center;
  transition: transform 200ms;
  transform-origin: right center;
}
.header-nav .link--back:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}
.header-nav .link--back:hover::after {
  transform: scaleX(1.5);
}
.header-nav .link--back:hover::before {
  transform: translateX(-1rem);
}
.social {
  display: flex;
  align-items: center;
  font-weight: var(--f-medium);
  margin-top: 2rem;
}
.social__links {
  display: flex;
  gap: calc(var(--spacer) / 5);
  margin-left: calc(var(--spacer) / 2);
}
.social__title {
  display: none;
}
.social a {
  --c-icon: var(--c-bg);
  --c-icon-bg: var(--c-primary);
  display: block;
  width: 1.7rem;
  height: 1.7rem;
}
.social a:not(:last-child) {
  margin-right: calc(var(--spacer) / 4);
}
.social a:hover {
  --c-icon: var(--c-secondary);
  --c-icon-bg: var(--c-bg);
}
.social a svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.social a svg [data-name=bg] {
  fill: var(--c-icon-bg);
}
.social a svg [data-name=icon] {
  fill: var(--c-icon);
}
.social a svg [data-name=icon] rect,
.social a svg [data-name=icon] path {
  fill: var(--c-icon);
}
.heroes {
  max-width: 100%;
  padding: 0;
  height: var(--h-heroes);
}
.heroes__list {
  width: 100vw;
  position: fixed;
}
.heroes__item {
  display: flex;
  min-height: var(--h-heroes);
}
.heroes__item::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgb(14, 21, 51);
  background: linear-gradient(180deg, rgb(14, 21, 51) 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
  opacity: 0.8;
  z-index: 3;
  height: 30vh;
  pointer-events: none;
  transition: all 200ms ease-out;
}
@media (min-width: 1540px) {
  .heroes__item {
    aspect-ratio: 16/9;
  }
}
.heroes__item .content {
  color: var(--c-bg);
  position: absolute;
  bottom: 10vh;
  padding: 0 var(--spacer);
}
@media (min-width: 768px) {
  .heroes__item .content {
    bottom: 10vh;
    left: 3rem;
    max-width: 80%;
  }
}
@media (min-width: 1024px) {
  .heroes__item .content {
    bottom: 10vh;
    left: 3rem;
    max-width: 60%;
  }
}
@media (min-width: 1540px) {
  .heroes__item .content {
    bottom: 20vh;
    left: 10rem;
    max-width: 50%;
  }
}
@media (min-width: 1920px) {
  .heroes__item .content {
    bottom: 30vh;
    left: 10rem;
    max-width: 40%;
  }
}
.heroes__item .content h1 {
  color: var(--c-bg);
}
.heroes__item .content p {
  font-size: 1.2em;
}
.heroes__item .figure__container {
  position: absolute;
  z-index: -1;
  inset: 0;
  height: 100%;
  width: 100%;
}
.heroes__item .figure__container img {
  object-fit: cover;
  object-position: bottom center;
  height: 100%;
  width: 100%;
}
.splide__pagination {
  --splide-nav-w: 10px;
  position: absolute;
  z-index: 5;
  right: calc(var(--spacer) * 2);
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacer) / 4);
}
.splide__pagination .splide__pagination__page {
  width: var(--splide-nav-w);
  height: var(--splide-nav-w);
  background-color: rgba(var(--rgb-primary), 0.4);
  border-radius: calc(var(--splide-nav-w) / 2);
  position: relative;
}
.splide__pagination .splide__pagination__page:hover, .splide__pagination .splide__pagination__page.is-active {
  background-color: var(--c-bg);
}
.splide__pagination .splide__pagination__page:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  inset: -100%;
  background-color: transparent;
}
.section__video {
  transform: translateY(calc(var(--poly-spacer) * -0.5));
  padding-top: calc(var(--poly-spacer) * 1);
  padding-bottom: calc(var(--poly-spacer) * 1);
  z-index: 2;
  animation-name: polyfull;
  animation-delay: var(--delay, 0);
  animation-duration: var(--d-poly);
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
  position: relative;
  position: relative;
  --c-bg: transparent;
  z-index: 2;
  background-color: var(--c-primary-dark);
}
@media (min-width: 768px) {
  .section__video {
    padding-top: calc(var(--poly-spacer) * 2.4);
    transform: translateY(calc(var(--poly-spacer) * -1));
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .section__video {
    padding-top: calc(var(--poly-spacer) * 2);
    padding-bottom: calc(var(--poly-spacer) * 1);
    transform: translateY(calc(var(--poly-spacer) * -1));
  }
}
.section__video::before {
  content: "";
  position: absolute;
  background-color: var(--c-bg);
  height: 6rem;
  top: calc(-6rem + 2px);
  width: 100%;
  display: block;
  z-index: 1;
  pointer-events: none;
}
.section__video::after {
  content: "";
  position: absolute;
  background-color: var(--c-bg);
  height: 10rem;
  bottom: -9rem;
  width: 100%;
  display: block;
  z-index: 1;
  pointer-events: none;
}
.section__video .bg {
  position: absolute;
  z-index: -2;
  inset: -10% 0 -10% 0;
  background-size: cover;
  background-position: center;
}
.section__video .row--full {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 33vh;
}
@media (min-width: 768px) {
  .section__video .row--full {
    min-height: 50vh;
  }
}
@media (min-width: 1024px) {
  .section__video .row--full {
    min-height: 70vh;
  }
}
.section__video .row--full::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgb(14, 21, 51);
  background: linear-gradient(90deg, rgb(14, 21, 51) 30%, rgba(255, 255, 255, 0) 100%);
  z-index: -1;
  inset: -100% 0 -100% 0;
}
.section__video .row--full .col {
  width: 90%;
}
@media (min-width: 768px) {
  .section__video .row--full .col {
    width: 80%;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .section__video .row--full .col {
    width: 50%;
  }
}
.section__video video {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: var(--aspect-ratio);
}
.video-responsive {
  position: relative;
  z-index: 5;
}
.video-responsive.is-playing .overlay {
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}
.video-responsive.is-playing:hover .overlay {
  opacity: 1;
}
.video-responsive.is-playing button {
  pointer-events: none;
}
.video-responsive .overlay {
  position: absolute;
  inset: 0.5rem;
  background-color: transparent;
  cursor: pointer;
}
.video-responsive .overlay button {
  width: 4rem;
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  aspect-ratio: 1/1;
  pointer-events: auto;
}
@media (min-width: 768px) {
  .video-responsive .overlay button {
    width: 8rem;
  }
}
.video-responsive .overlay button svg {
  position: absolute;
  transform: scale(1.3);
}
.video-responsive .overlay button svg.hide {
  opacity: 0;
  pointer-events: none;
}
.video-responsive .overlay button svg circle {
  fill: rgba(255, 255, 255, 0.4);
}
.video-responsive .overlay button svg path {
  fill: white;
}
.video-responsive .overlay button:hover circle {
  fill: rgba(255, 255, 255, 0.4);
}
.video-responsive .overlay .video__title {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  left: 1rem;
  color: white;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  font-size: 1.1rem;
}
.flexibles--key-benefits .col {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacer) * 2);
}
.flexibles--key-benefits .col .subtitle,
.flexibles--key-benefits .col .title {
  color: var(--c-primary-dark);
}
.flexibles--key-benefits .col .subtitle strong,
.flexibles--key-benefits .col .title strong {
  color: var(--c-secondary);
}
.flexibles--key-benefits header {
  text-align: center;
}
.flexibles--key-benefits header .subtitle {
  font-size: 1.7em;
  line-height: 1.2;
  font-weight: var(--f-medium);
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .flexibles--key-benefits .benefits__list {
    padding-left: var(--p-outside);
    padding-right: var(--p-outside);
  }
}
.flexibles--key-benefits .benefits__list ol {
  display: grid;
  padding: 0;
  margin: 0;
  gap: var(--spacer);
}
@media (min-width: 768px) {
  .flexibles--key-benefits .benefits__list ol {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(var(--break-after, 2), 1fr);
    grid-auto-flow: column;
  }
}
.flexibles--key-benefits .benefits__list li {
  background-color: var(--c-primary-dark);
  color: var(--c-bg);
  padding: calc(var(--spacer) / 2);
  height: 100%;
  -moz-column-break-inside: avoid;
       break-inside: avoid-column;
  margin-bottom: var(--spacer);
  display: flex;
  align-items: center;
  padding-left: calc(var(--size) + var(--spacer) * 3);
}
@media (min-width: 768px) {
  .flexibles--key-benefits .benefits__list li {
    padding: calc(var(--spacer));
    padding-left: calc(var(--size) + var(--spacer) * 4);
  }
}
.flexibles--key-benefits .benefits__list li strong {
  color: var(--c-secondary);
  font-weight: var(--f-light);
}
.flexibles--key-benefits .benefits__list li::before {
  font-weight: var(--f-bold);
  font-feature-settings: "tnum";
  color: var(--c-secondary);
  border-radius: 0;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_12___});
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  left: calc(var(--spacer) * 1);
  font-size: 1.4em;
  width: 45px;
  height: 45px;
  background-position-x: 4px;
  background-position-y: 4px;
}
@media (min-width: 768px) {
  .flexibles--key-benefits .benefits__list li::before {
    left: calc(var(--spacer) * 1.5);
    font-size: 2em;
    width: 61px;
    height: 61px;
    background-position-x: 4px;
    background-position-y: 4px;
  }
}
.link--stretched {
  display: flex;
  justify-content: center;
}
.link--stretched::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
.link--cta {
  position: relative;
}
.link--cta p,
.link--cta div {
  padding-left: 20px;
  display: inline-block;
  text-transform: uppercase;
  line-height: 1;
  color: var(--c-primary-dark);
  transition: transform 200ms ease-in;
  font-size: 1.2em;
}
.link--cta p strong,
.link--cta div strong {
  color: var(--c-primary);
  font-weight: var(--f-medium);
}
.link--cta::before {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_13___});
  background-repeat: no-repeat;
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 100px;
  top: -47px;
  left: -20px;
  transition: transform 200ms ease-in;
}
@media (hover: hover) {
  .link--cta:not(.no-hover):hover span {
    transform: translateX(1rem);
  }
  .link--cta:not(.no-hover):hover::before {
    transform: translateX(-1rem) scale(1.2);
  }
}
.link__btn {
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.link__btn span {
  transition: transform 250ms ease-out;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--f-bold);
  font-size: 1rem;
}
@media (min-width: 768px) {
  .link__btn span {
    font-size: 0.95rem;
    line-height: 1;
  }
}
@media (min-width: 1540px) {
  .link__btn span {
    font-size: 1.188rem;
  }
}
.link__btn--reverse .arrow {
  transform: rotate(180deg);
}
.link__btn .arrow {
  --arrow-width: calc(var(--spacer) * 1.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.2rem;
  height: 100%;
}
.link__btn .arrow svg {
  width: 7px;
  height: 7px;
}
.link__btn .arrow svg circle {
  fill: var(--btn-link-color);
}
.link__btn .arrow svg {
  transition: transform 200ms ease-out;
}
.link__btn .arrow::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 1px;
  background: var(--c-primary);
  transition: transform 200ms ease-out;
  width: var(--arrow-width);
}
.link__btn:hover span {
  transform: translateX(5%);
}
.link__btn:hover .arrow svg {
  transform: translateX(var(--arrow-width));
}
.link__btn:hover .arrow::after {
  transform: translateX(-0.2rem);
}
.link__btn--previous {
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-right: 2rem;
}
.link__btn--previous .arrow {
  margin-right: 1rem;
  margin-left: 0;
}
.link__btn--previous:hover span {
  transform: translateX(-5%);
}
.link__btn--next span {
  padding-right: 1.2rem;
}
.link__btn--next .arrow {
  transform: rotate(180deg);
  margin-left: 0;
}
.news-item {
  /* Store the current selector to use it in hover behavior */
  position: relative;
  overflow: hidden;
  height: 100%;
  /* Hover behavior */
}
.news-item::after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(14, 21, 51, 0.1), rgba(14, 21, 51, 0.8) 65%);
  opacity: 1;
}
.news-item__content {
  color: var(--c-bg);
  padding: 1.4em;
  content: "";
  position: absolute;
  display: block;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: grid;
  grid-template-rows: 1fr;
  gap: 1rem;
  grid-template-areas: "title" "content" "bottom";
}
.news-item__content .news-item__titles {
  grid-area: title;
}
.news-item__content .news-item__footer {
  grid-area: bottom;
}
.news-item__content--hover {
  grid-area: content;
  color: var(--c-bg);
  max-width: 100%;
  align-self: end;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .news-item__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .news-item__content--hover {
    content: "";
    position: absolute;
    display: block;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateY(150%);
    transition: transform 200ms ease-out;
    top: auto;
    max-width: 67%;
    color: var(--c-text);
    background-color: var(--c-bg);
    padding: 1.4em;
  }
}
@media screen and (min-width: 1024px) and (hover: hover) and (min-width: 1540px) {
  .news-item__content--hover {
    max-width: 60%;
  }
}
@media (min-width: 768px) {
  .news-item__content footer {
    flex-direction: row;
    justify-content: space-between;
  }
  .news-item__content footer a:not(:last-child) {
    margin-right: 2rem;
  }
}
.news-item__titles {
  font-weight: var(--f-bold);
  text-transform: uppercase;
  transform: translateY(0);
  transition: transform 200ms ease-in;
}
.news-item__titles--title {
  font-size: 2.2em;
  line-height: 1.2em;
  display: block;
}
@media (min-width: 1920px) {
  .news-item__titles--title {
    font-size: 2.5em;
  }
}
.news-item__titles--subtitle {
  font-weight: var(--f-light);
  font-size: 1.2em;
}
@media (min-width: 1920px) {
  .news-item__titles--subtitle {
    font-size: 1.39em;
  }
}
.news-item__footer {
  font-weight: var(--f-bold);
  font-size: 0.777em;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}
.news-item__date {
  display: flex;
  align-items: center;
}
.news-item__date::before {
  content: "";
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_14___});
  margin-right: 0.5rem;
}
.news-item__label {
  width: auto;
  display: flex;
  justify-content: flex-end;
  gap: 0.7rem;
  align-items: center;
  /* Set btn link color */
  --btn-link-color: var(--c-bg);
}
.news-item__label .arrow:after {
  background-color: var(--c-bg);
}
.news-item__label svg {
  width: 7px;
  height: 7px;
}
.news-item__label svg circle {
  fill: var(--btn-link-color);
}
.news-item__label > svg {
  margin: 0 1rem;
}
.news-item__label--label .arrow {
  --arrow-width: 1.5rem;
}
.news-item__image img {
  transition: transform 200ms ease-in-out;
}
@media (hover: hover) {
  .news-item:hover .news-item__content--hover {
    transform: translateY(0);
  }
  .news-item:hover .news-item__titles {
    transform: translateY(240%);
  }
  .news-item:hover .news-item__image img {
    transform: scale(1.2);
    transition: transform 6000ms ease-in;
  }
  .news-item:hover .news-item__label .arrow svg {
    transform: translateX(calc(var(--arrow-width) + 0.5rem));
  }
  .news-item:hover .news-item__label .arrow::after {
    transform: translateX(0.2rem);
  }
}
.search__form {
  margin-left: 0;
  overflow: hidden;
  z-index: 12;
  align-items: center;
  display: none;
}
.search__form form {
  width: 0;
  display: none;
}
.search__form form input {
  min-width: 300px;
  transition: width 200s ease-in;
  padding: 0.4rem 0.7rem;
  animation-name: fade, right;
  animation-delay: 400ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .search__form .btn__search {
    margin-top: 0;
  }
}
.search__form .btn__search--close {
  display: none;
  position: relative;
  z-index: 3;
  margin: 0.6rem;
}
.search__form::after {
  position: absolute;
  content: "";
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
          clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  background-color: var(--c-primary);
  width: 110%;
  height: 110%;
  display: block;
  z-index: 2;
  background-color: var(--c-bg);
  width: var(--hexa-size);
  height: var(--hexa-size);
  top: calc(var(--hexa-size) / -2);
  left: calc(var(--hexa-size) / -4 + var(--hexa-size) - 11%);
  z-index: 1;
  animation-name: fade, top;
  animation-delay: 0;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.search__form::before {
  position: absolute;
  content: "";
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
          clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  background-color: var(--c-primary);
  width: 110%;
  height: 110%;
  display: block;
  z-index: 2;
  background-color: var(--c-bg);
  width: var(--hexa-size);
  height: var(--hexa-size);
  top: calc(var(--hexa-size) / -2);
  left: calc(var(--hexa-size) / -4);
  z-index: 1;
  animation-name: fade, top;
  animation-delay: 100ms;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.search__form.open {
  --hexa-size: 260px;
  position: relative;
  overflow: inherit;
  display: flex;
  --hexa-width: calc(1.7320508075689 * var(--hexa-size));
}
@media (min-width: 768px) {
  .search__form.open {
    --hexa-size: 300px;
    position: fixed;
    right: 10%;
  }
}
.search__form.open::after {
  animation-name: fade, bottom;
  animation-delay: 0;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.search__form.open::before {
  animation-name: fade, bottom;
  animation-delay: 100ms;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.search__form.open .btn__search {
  display: none;
}
.search__form.open .btn__search--close {
  display: inline-block;
}
.search__form.open .btn__search--close svg path {
  fill: var(--c-primary-dark);
}
.search__form.open form {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 6;
  width: auto;
  max-width: 70vw;
}
.search__form.open form label {
  padding: 0;
}
.search__form.open form label span {
  display: none;
}
.search__form.open form input {
  animation-name: fade, left;
  animation-delay: 400ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.search__form.open form [type=search] {
  border: 1px solid var(--c-primary-dark);
}
.search__form.open form [type=search]::-webkit-search-cancel-button {
  display: none;
}
.search__form.open form [type=submit] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  min-width: auto;
  padding: 0.45rem 1.4rem;
  background-color: var(--c-primary-dark);
}
.faqs__footer .row {
  display: grid;
  align-items: center;
  gap: calc(var(--spacer) * 4);
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .faqs__footer .row {
    grid-template-columns: 1fr 1fr;
  }
}
.faqs__footer .row.no-link {
  grid-template-columns: 1fr;
  justify-content: center;
}
.faqs__footer .row.no-link .col {
  margin: 0 auto;
}
.faqs__container .row .faq__row {
  padding: 0 var(--small-spacer);
}
@media (min-width: 768px) {
  .faqs__container .row .faq__row {
    padding: 0 var(--spacer);
  }
}
@media (min-width: 1540px) {
  .faqs__container .row .faq__row {
    max-width: 100%;
  }
}
.faqs__container .row h3,
.faqs__container .row h2 {
  text-transform: uppercase;
  color: var(--c-primary-dark);
}
.faqs__group {
  margin-bottom: var(--spacer);
  counter-reset: css-counter 0;
}
.faqs__group h3 {
  font-size: 1.4em;
  margin-bottom: calc(var(--spacer) / 2);
  margin-left: calc(var(--spacer) / 2);
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacer);
}
.faqs__group h3::before {
  content: "";
  height: 2px;
  width: calc(var(--spacer) * 2);
  background: var(--c-primary);
}
.faqs__group details {
  --w-marker: 2rem;
  --mr-marker: 0.8rem;
  margin: 0 auto calc(var(--spacer) / 4) auto;
  counter-increment: css-counter 1;
  padding-left: calc(var(--spacer) / 2);
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .faqs__group details {
    --w-marker: 2.5rem;
    --mr-marker: 0.8rem;
  }
}
.faqs__group details summary {
  background-color: var(--c-primary-dark);
  color: var(--c-bg);
  padding: calc(var(--spacer) / 2) calc(var(--spacer) * 3) calc(var(--spacer) / 2) 0;
  position: relative;
  display: flex;
  font-size: 1em;
  font-weight: var(--f-bold);
  cursor: pointer;
}
.faqs__group details summary::marker, .faqs__group details summary::-webkit-details-marker {
  display: none;
}
.faqs__group details summary::before {
  content: counter(css-counter) ". ";
  display: block;
  margin-right: var(--mr-marker);
  min-width: var(--w-marker);
  text-align: right;
}
.faqs__group details summary::after {
  content: "";
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_15___});
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  right: 1.5rem;
  background-position: center;
  position: absolute;
  height: 1rem;
  top: 50%;
  width: 1.5rem;
}
@media (min-width: 768px) {
  .faqs__group details summary::after {
    width: 2.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }
}
.faqs__group details[open] summary::after {
  transform: translateY(-50%) rotate(-90deg);
}
.faqs__group details .content {
  padding: calc(var(--spacer) * 1) calc(var(--mr-marker) + var(--w-marker));
}
@media (min-width: 768px) {
  .faqs__group details .content {
    padding: calc(var(--spacer) * 2) calc(var(--mr-marker) + var(--w-marker));
  }
}
.page-template-template-faq h1 {
  font-size: 2.8rem;
  color: var(--c-primary-dark);
  font-weight: var(--f-light);
  text-transform: uppercase;
}
.colophon {
  border-top: 1px solid #98a2ae;
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.86em;
}
.hexagones {
  position: absolute;
  inset: 0;
  min-height: 100%;
  height: 120%;
  z-index: 3;
  pointer-events: none;
  touch-action: none;
  overflow: hidden;
}
@media screen and (min-width: 1024px) and (hover: hover) {
  .hexagones {
    mix-blend-mode: hard-light;
  }
}
.hexagones.full-height {
  height: calc(var(--h-app) * 0.8);
}
.separator-canvas__container.sep-canvas {
  position: absolute;
  top: -6rem;
  width: 100%;
  background-color: var(--c-bg);
  z-index: 5;
}
.share {
  display: flex;
  align-items: center;
  gap: var(--spacer);
  margin: var(--spacer) 0;
}
.share a span {
  display: none;
}
.share a svg {
  display: block;
}
.share a:hover {
  opacity: 0.8;
}
.modal {
  position: fixed;
  inset: 0;
  display: none;
  z-index: 15;
  opacity: 1;
  background-color: var(--c-bg);
}
.modal .btn__menu {
  position: absolute;
  top: var(--spacer);
  right: var(--spacer);
}
.modal:not(#nav-primary) {
  z-index: 21;
  padding: var(--small-spacer);
}
@media (min-width: 768px) {
  .modal:not(#nav-primary) {
    padding: var(--spacer);
  }
}
.modal.active {
  display: block;
  animation-name: fade;
  animation-delay: 200ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.modal .container {
  min-height: 100%;
  overflow-y: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 5;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
@media (min-width: 768px) {
  .modal .container {
    max-width: 80%;
    align-items: center;
  }
}
.modal.active .container {
  opacity: 1;
}
body[class*=show--] {
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
}
body[class*=show--] .branding {
  position: fixed;
  z-index: 20;
  animation-name: right, fade;
  animation-delay: 450ms;
  animation-duration: 300ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: backwards; /* Set the first frame first before delay */
  animation-play-state: running;
}
.figure__container {
  position: relative;
}
.figure figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.9em;
  padding: 0.6em 0.8em;
  background-color: #FFF;
}
.figure .description {
  padding: 0.6em 0.4em;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
